import React from 'react'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosObj from '@/store/axios'
import { getEitherCustIdFromLs } from '@/utils/useUserInfo'
interface ToggleFavoritePayload {
    postData: {
        itemId: string;
        isFavorite: boolean;
        type: string;
        itemAdditionalData?: any;
    }
}
interface ToggleFavoriteResponse {
    itemId: string;
    isFavorite: boolean;
    type: string;
    itemAdditionalData: any;
}
// Fetch customer's favorites and sync with the state
export const fetchFavoritesThunk = createAsyncThunk<any, string, { rejectValue: string }>(
    'favorites/fetchFavorites',
    async (customerId, { rejectWithValue }) => {
        try {
            const response = await axiosObj.get(
                `/api/customer-favorites?customerId=${customerId}`
            );
            return response.data;
        } catch (error) {
            const errorMessage =
                (error as any)?.response?.data?.message || 'Failed to fetch favorite';
            return rejectWithValue(errorMessage);
        }
    }
);

// Toggle favorite (Optimistic Update)
export const toggleFavoriteThunk = createAsyncThunk<
    ToggleFavoriteResponse,
    ToggleFavoritePayload,
    { rejectValue: string }
>(
    'favorites/toggleFavorite',
    async ({ postData }, { rejectWithValue }) => {
        const { itemId, isFavorite, type, itemAdditionalData } = postData;
        const { productImage, price, comparePrice, model3dUrl, availability, productName, image, name } = itemAdditionalData
        try {
            if (isFavorite) {
                await axiosObj.delete('/api/customer-favorites', {
                    data: {
                        customerId: getEitherCustIdFromLs(),
                        itemId,
                        type,
                    }
                });
                return { itemId, isFavorite: false, type, itemAdditionalData };
            } else {
                await axiosObj.post('/api/customer-favorites', {
                    customerId: getEitherCustIdFromLs(),
                    itemId,
                    type, price, comparePrice, model3dUrl, availability,
                    image: productImage ? productImage : image,
                    name: productName ? productName : name,
                });
                return { itemId, isFavorite: true, type, itemAdditionalData };
            }
        } catch (error) {
            const errorMessage =
                (error as any)?.response?.data?.message || 'Failed to toggle favorite';
            return rejectWithValue(errorMessage);
        }
    }
);