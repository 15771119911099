import { Box, Typography } from "@mui/material";
import Link from "next/link";
import { CarouselCardData as CardData } from "../carousal/carousal_card";
import cssStyles from "./category_cards.module.scss";
import React from "react";
import Image from 'next/image';
import { getUserInfo } from "@/utils/useUserInfo";

export default function GenerateCategoryCard(cards: CardData[], actionFn?: Function, 
height?: string, width?: string, pageName?: string): any[] {
    const categoryCardsEls: any[] = [];

    const handleCustomerRecentlyViewed = async (card: any) => {

        const userInfo = await getUserInfo("",true);

        const recentlyViewedURL = `https://nestingale.com/v1/api/customer/recently-viewed?customerId=${userInfo.cId}&itemId=${card.id}&type=product`;
      
        try {
          console.log('Posting data to', recentlyViewedURL, 'to track customerRecentlyViewed');
      
          const response = await fetch(recentlyViewedURL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              customerId: userInfo.cId,
              itemId: card.id,
              type: 'product',
              image: card.imageUrl,
              name: card.name,
              price: card.price,
              comparePrice: card.comparePrice,
              model3dUrl: card.model3dUrl || null,
            }),
          });
      
          if (!response.ok) {
            throw new Error(`Failed to track recently viewed item: ${response.statusText}`);
          }
      
          console.log('Successfully tracked recently viewed item:',card.id);
        } catch (error) {
          console.error('Error tracking recently viewed item:', error);
        }
    };

    cards?.forEach((card: CardData, index: number) => {
        const prodImage = card.imageUrl;

        categoryCardsEls.push(
            <Box component='div' className={cssStyles["card"]} key={index} onClick={(e: any) => {
                actionFn && actionFn(e, index, card, pageName)
                handleCustomerRecentlyViewed(card);
            }}>

                <div>
                    <a href={card.link ?? "#"} id={pageName + " - " + (card.trackObj?.clickId ?? card.id ?? card.name)}>
                        <Image
                            src={prodImage || ""}
                            alt={card.name || ""}
                            width={parseInt(width as string ?? "200")}
                            height={parseInt(height as string ?? "200")}
                            objectFit="contain"
                            className={cssStyles["image-wrapper"]}
                        />
                    </a>
                </div>

                <Typography className={cssStyles["text"]} component="div">
                    {card.name}
                </Typography>
            </Box>
        );
    });

    return categoryCardsEls;
}