import Head from 'next/head';
import CssBaseline from '@mui/material/CssBaseline';
import LogoComponent from './logo';
import ControlBar from './builder/ControlBar';
//import FooterComponent from './footer';


export default function BuilderLayout(props) {
  const { children, brand } = props; 
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <div className='builder-main-wrapper'>
        <div id="signup-a">
          <div className='headsection' style={{ display: 'flex', backgroundColor: 'white' }}>   
            <LogoComponent {...brand} isBuilder={true} />
            <div id='projectNamePlaceHolder' className='project-nam-place-holder'>
              <ControlBar />
            </div>
          </div>
          <div id="my-home">
            <CssBaseline />
            <main>{children}</main>
          </div>
          {/*<div className='footersection'>
            <FooterComponent brand={...brand} />
          </div>*/}
        </div>
      </div>
    </>
  )
}