import React, { useState, useMemo } from 'react';
import styles from './header_menu.module.scss';
import { Link, ListItem, ListItemButton } from "@mui/material";
import TabbedMenuPanel, { Menu } from './tabbed_menu_panel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { trackItem } from '@/utils';

import GetStartedOverlay from '@/components/new_hero_section/getStartedOverlay';
import BuilderSelect from '@/store/builderStoreSelector';
import { useDispatch } from 'react-redux';
import { actions } from '@/store/builderSlice';
import { useRouter } from 'next/router';

interface MenuItem {
    name: string;
    link?: string;
    method?: string;
    subMenu?: Menu[];
    trackInfo?: any;
    align?: 'left' | 'right';
    styles?: any;
}

interface HeaderMenuItemProps {
    deviceType?: 'mobile' | 'desktop';
    menuItem: MenuItem;
    overlayCallback: (open: boolean) => void;
}

interface HeaderMenuProps {
    deviceType?: 'mobile' | 'desktop';
    menuJson: { [key: string]: MenuItem };
    setOpenDrawer?: (open: boolean) => void;
}


const HeaderMenuItem: React.FC<HeaderMenuItemProps> = ({ deviceType, menuItem , overlayCallback }) => {
    const router=useRouter();
    const dispatch=useDispatch();
    const { name, link, subMenu, trackInfo, method } = menuItem;
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const isShowGeneratePreviewSettledIndicator = BuilderSelect.generatePreview().showGeneratePreviewSettledIndicator;
    const handleMouseEnter = () => { deviceType !== 'mobile' && setDropdownVisible(true) };
    const handleMouseLeave = () => { deviceType !== 'mobile' && setDropdownVisible(false) };
    const headerMenuItemClick = (menuTitle: string) => {
        setDropdownVisible(true);
        trackItem(trackInfo || {});
    };
    const showIndicator = (menuTitle: string) => {
        if (menuTitle.toLowerCase() === 'my projects' && isShowGeneratePreviewSettledIndicator&&(!router.pathname.includes('/projects'))) {
            return <span style={{  height: '5px',
                width: '5px',
                backgroundColor: '#FE6526',
                borderRadius: '50%',
                display:'inline-block',
                position:'absolute',
                top:0,
                right:5,
                }}></span>;
        }
        return null;
    };

    const memoizedTabbedMenuPanel = useMemo(() => {
        const subMenuClass = deviceType === 'mobile' ? styles.header_menu_wrapper_mobile : styles.header_menu_wrapper;
        const menuClass = isDropdownVisible ? `${subMenuClass} ${styles.visible}` : subMenuClass;
        return (
            <div className={menuClass} id={name + " memorized tabbed menu pannel"}>
                {deviceType === 'mobile' && (
                    <CloseIcon
                        fontSize="large"
                        style={{
                            position: 'absolute',
                            top: 40,
                            right: 30,
                            cursor: 'pointer',
                            color: 'inherit',
                        }}
                        onClick={() => setDropdownVisible(false)}
                    />
                )}
                {subMenu && <TabbedMenuPanel menuTitle={name} menuJson={subMenu} deviceType={deviceType} />}
            </div>
        );
    }, [subMenu, isDropdownVisible, deviceType, name]);

    return (
        <div className={styles.header_menu_item_wrapper}
            onMouseEnter={subMenu && handleMouseEnter}
            onMouseLeave={subMenu && handleMouseLeave}
        >
            {deviceType === 'mobile'
                ?   /* If the menu has subMenu, then render the subMenu arrow icon only */
                <div id={"MenuTitle " + name} style={{ display: "flex", justifyContent: "left" }}>
                    <a href={link} id={"MenuTitleLink " + name}
                        onClick={() => {
                            trackItem(trackInfo || {});
                            if (method === 'openGetStartedModal') {
                                overlayCallback(true);
                            };
                        }}>{name}</a>
                    <ChevronRightIcon fontSize="large" sx={{ color: "teal", marginTop: "4px", marginLeft: "20px" }} onClick={() => headerMenuItemClick(name)} />
                </div>
                :   /* for desktop, it renders all menu links */
                <div className={styles.header_menu_item}>
                    <a href={link} id={"MenuTitleLink " + name}
                        className={styles.header_menu_link}
                        onClick={(e) => {
                            trackItem(trackInfo || {});
                            if (method === 'openGetStartedModal') {
                                overlayCallback(true);
                            };
                            if(name.toLowerCase()==='my projects'){
                                dispatch(actions.showGeneratePreviewSettledIndicator(false))
                            }
                        }}
                    ><span style={menuItem?.styles}>{name}</span>{showIndicator(name)}</a>
                </div>
            }
            {subMenu && memoizedTabbedMenuPanel}
        </div>
    );
};

const HeaderMenu: React.FC<HeaderMenuProps> = ({ deviceType, menuJson, setOpenDrawer }) => {

    const [overlayOpen, setOverlayOpen] = useState(false)
    function onCloseOverlay() {
    setOverlayOpen(false)
    }

    return (
        <>
            {deviceType === 'mobile' ? (
                <div className={styles.drawer_header}>
                    {
                        Object.keys(menuJson).map((key: string, index) => (
                            <React.Fragment key={index}>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        {menuJson[key].subMenu ? (
                                            <HeaderMenuItem
                                                deviceType="mobile"
                                                menuItem={menuJson[key]}
                                                overlayCallback={setOverlayOpen}
                                            />
                                        ) : (
                                            <a href={menuJson[key].link} id={"MenuTitleLink " + menuJson[key].name}
                                                onClick={() => { trackItem(menuJson[key].trackInfo || {});
                                                if (menuJson[key].link == "/deals" || menuJson[key].method === 'openGetStartedModal') {
                                                    setOverlayOpen(true);
                                                    setOpenDrawer && setOpenDrawer(false);
                                                };
                                                }}>
                                                {menuJson[key].name}
                                            </a>
                                        )}
                                    </ListItemButton>
                                </ListItem>
                            </React.Fragment>
                        ))}
                </div>
            ) : (
                <div className={styles.header_menu}>
                    <div className={styles.header_menu_left}>
                        {Object.keys(menuJson)
                            .filter(key => menuJson[key].align !== 'right')
                            .map((key, index, array) => (
                                <React.Fragment key={index + "_header_menu_left"}>
                                    <HeaderMenuItem
                                        deviceType="desktop"
                                        menuItem={menuJson[key]}
                                        overlayCallback={setOverlayOpen}
                                    />
                                    {array.length > index + 1 && <div className={styles.header_menu_separator}>|</div>}
                                </React.Fragment>
                            ))}
                    </div>
                    <div className={styles.header_menu_right}>
                        {Object.keys(menuJson)
                            .filter(key => menuJson[key].align === 'right')
                            .map((key, index, array) => (
                                <React.Fragment key={index + "_header_menu_right"}>
                                    <HeaderMenuItem
                                        deviceType="desktop"
                                        menuItem={menuJson[key]}
                                        overlayCallback={setOverlayOpen}
                                    />
                                    {array.length > index + 1 && <div className={styles.header_menu_separator}>|</div>}
                                </React.Fragment>
                            ))}
                    </div>
                </div>
            )}
            <GetStartedOverlay open={overlayOpen} onClose={onCloseOverlay} currentView={"getStartView"} />
        </>

    );
};

export default HeaderMenu;