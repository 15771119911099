import React, {useState,useEffect, useRef,} from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography,responsiveFontSizes,Theme, Grid,useMediaQuery,} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import cssStyles from "./roomselection.module.scss";
import { getUserInfo } from "@/utils/useUserInfo";
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { getRoomTypes } from "@/pages/api/roomType";
import HelpMe from "@/components/help_me/help_me";
import { createProject, getProjects } from "@/store/projects";
import { getRoomDesigns } from "@/store/homeSlice";
import { getRandomInRange } from "@/utils/helper";
import { gtmPageOnloadTracking, gtmClickTracking } from "@/utils";
import Skeleton from '@mui/material/Skeleton';
import { DesignOverlay } from "@/components/design_details_page";
import axios from "axios";
import { navigateAndReplaceHistory } from "@/utils";
import { useRouter } from "next/router";
import RoomPlanOnboarding from "@/pages/[vendor]/roomplanonboarding";




interface RoomSelectionProps {
    isAllRecommendationsRequired?: boolean;
    onImageClickHandler?: () => void;
}
const RoomSelection: React.FC<RoomSelectionProps> = ({isAllRecommendationsRequired = false, onImageClickHandler = () => {}}) => {
  const [roomTypes, setRoomTypes] = useState([]);
  const [roomTypeSelected, setRoomType] = useState<boolean>(false);
  const [currentRoomTypeDisplayName, setCurrentRoomTypeDisplayName] = useState<string>('');
  const [recommendation, setRecommendation] = useState<any>();
  const [isMobile, setIsMobile] = useState(false);
  const [isLargeDesk, setisLargeDesk] = useState(false);
  const [showAllRecommendations, setShowAllRecommendations] = useState<boolean>(isAllRecommendationsRequired);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [roomPlanOnboarding, setRoomPlanOnboarding] = useState(false);
  const [selectedDesign, setSelectedDesign] = useState<any>(null);
  const [selectedRoomType, setSelectedRoomType] = useState<any | null>(null);
  const [isLoaderVisible, setLoaderVisibility] = useState<boolean>(false);  const [loading, setLoading] = useState<boolean>(false);
  const [showAll, setShowAll] = useState(false);
  const [roomTypesMapLoaded, setRoomTypesMapLoaded] = useState(false);
  const [selectedButton, setSelectedButton] = useState<string | null>(null);
  const[localFav,setLocalFav]=useState([])
  const[fav,setFav]=useState<any>([])
  useEffect(()=>{
    setLocalFav(fav)
  },[fav])
function ontoggleFav(designId:any, isFavorited:boolean,cId:any) {
  // console.log(cId,'cId--------------------')
  if(cId){
    setFav((prevState:any) => {
      const itemExists = prevState.some((item:any) => item.designId === designId);
      if (itemExists) {
        return prevState.map((eachItem:any) =>
          eachItem.designId === designId
            ? { ...eachItem, isFav: !eachItem.isFav }
            : eachItem
        );
      } else {
        return [...prevState, { cId,designId, isFav: isFavorited }];
      }
    });
  }
  }
  // console.log(fav,'user-interacted favItems')
  const showFavFromFavList=fav.find( (eachDesign: any) => eachDesign?.designId === selectedDesign?.designId)
  useEffect(()=>{
    localStorage.setItem('favoritedDesigns',JSON.stringify(localFav))
  },[localFav])
  const CUSTOMER_FAVORITES_URL =
      "https://eh94zzqmpb.execute-api.us-east-1.amazonaws.com/dev/nest/v1/api/customer-favorites";
  useEffect(()=>{
    const favDesignsInLocalStorage=localStorage.getItem('favoritedDesigns')
    if(favDesignsInLocalStorage!==null){
      const parsedFavDesignsList=JSON.parse(favDesignsInLocalStorage)
      if(parsedFavDesignsList?.length>0){
        parsedFavDesignsList.map((eachFavDesign:any)=>{
          async function postFavHandler(){
            const postData = {
              customerId: eachFavDesign?.cId,
              itemId: eachFavDesign?.designId,
              type: "design",
            };
            try {
              let response;
              if (!eachFavDesign?.isFav) {
                response = await axios.delete(CUSTOMER_FAVORITES_URL, {
                  data: postData,
                });
            
                if(response?.data?.status.toLowerCase()==='removed'){
                  setLocalFav(prevState=>prevState.filter((eachDesign:any)=>eachDesign?.designId!==eachFavDesign?.designId))
                
                }
                gtmClickTracking({
                  event:'Design Unfavorited',
                  pageType: "new design details page pop-up",
                  designUnFavorited:eachFavDesign?.designId
                })
              } else {
                response = await axios.post(CUSTOMER_FAVORITES_URL, postData, {
                  accept: "application/json",
                } as any);
                if(response?.data?.status.toLowerCase()==='saved'){

                  setLocalFav(prevState=>prevState.filter((eachDesign:any)=>eachDesign?.designId!==eachFavDesign?.designId))
                }
                gtmClickTracking({
                  event: "Design Favorited",
                  pageType: "new design details page pop-up",
                  designFavorited:eachFavDesign?.designId
                })
              }
            } catch (e) {
              console.log(e,'err at fav');
            }
          }
          postFavHandler()
        })
      }
    }
  },[localFav])


  const router = useRouter();
  const { slug } = router.query;

  useEffect(() => {
    if(!router.isReady) {
      return;
    }

    const onLoad = async() => {
      if(showAllRecommendations) {
          const userInfo = await getUserInfo("",true);
          setUserInfo(userInfo);
          getRecommendations(userInfo);
      }
    }

    async function fetchRoomTypes() {
        try {
            const roomTypeData = await getRoomTypes();
            let roomTypes = roomTypeData.roomTypes;
            roomTypes = roomTypes.filter((roomType: any) => roomType.status === 'active' || roomType.status === 'pending');
            setRoomTypes(roomTypes);
        } catch (error) {
            console.error("Error fetching room types:", error);
        }
    }

    onLoad();
    fetchRoomTypes();

    gtmPageOnloadTracking({
      event: 'room_selector_page_view',
      pageType: "Room Selection page"
    });
  
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767); // Detect mobile screen
      setisLargeDesk(window.innerWidth >= 1600); // Detect Large Desktop 1280px
    };

    handleResize(); // Check on component mount
    window.addEventListener("resize", handleResize); // Listen for window resize events

    if(slug && slug.length > 0) {
      router.push('/roomselection');
    }

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup on unmount
    };

  }, [router.isReady]);

  useEffect(() => {
    const handleBackNavigation = (event: PopStateEvent) => {
      if(!router.isReady) {
        return;
      }

      if(router.query.slug) {
        window.location.href = '/roomselection';
      } else {
        router.push('/');
      }
    };

    window.addEventListener('popstate', handleBackNavigation);
    
    return () => {
      window.removeEventListener('popstate', handleBackNavigation);
    };
  }, [router]);

  const handleRecommendationChange = (design: any) => {
    gtmClickTracking({
      event: 'style_design_selected', 
      design
    });
    setSelectedDesign(design);
  };

  const recommendationDesignsBasedOnSelectedDesign = recommendation?.designs?.filter(
    (eachRecommendation: any) =>
      eachRecommendation?.roomType.toLowerCase() ===
        selectedDesign?.roomType.toLowerCase() &&
      eachRecommendation?.style.toLowerCase() ===
        selectedDesign?.style.toLowerCase()&&eachRecommendation?.designId !==
        selectedDesign?.designId
  );

  const routeToRoomQuiz = () =>{

    {isAllRecommendationsRequired ?  
      (gtmClickTracking({
        event: 'need_help_clicked',
        roomType : currentRoomTypeDisplayName
      })
    ) : (
      gtmClickTracking({
        event: 'Help me selected',
        roomType : currentRoomTypeDisplayName
      })
    )
    }
    let currentRoomTypeName = currentRoomTypeDisplayName.toLowerCase().replace(/\s+/g, '');
    let roomQuiz = `/roomquiz?roomType=${currentRoomTypeName}`;
    window.location.href = roomQuiz;
  }

  const handleOverlay = (design: any) => {
    if (showAllRecommendations) {
    setSelectedDesign(design); // Set selected design for the overlay
    setOverlayOpen(true); // Open overlay
    }else{
      setSelectedDesign(design); // Set selected design for the overlay
    setOverlayOpen(true); // Open overlay
    }
    };

  const handleProjectPage = async (design: any) =>{
  //   let project: any = await handleProjects(design);
  // const origin =
  //   typeof window !== 'undefined' && window.location.origin
  //       ? window.location.origin
  //       : '';
  //   let projectUrl = `${origin}/project?projectId=${project.projectId}`;
  //   // window.location.href = projectUrl;
  //   router.push(projectUrl)

  let url = '/roomplanonboarding?roomType=bedroom';
  window.location.href = url;
    
  }
  useEffect(() => {
    const handleRouteChangeComplete = () => {
      setOverlayOpen(false)
      setSelectedDesign(null)
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    // Cleanup event listener on unmount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events]);
  
  const handleProjects = async (design: any) => {
    let roomType: any = roomTypes.filter((roomType: any) => roomType.roomTypeDisplayName == currentRoomTypeDisplayName);
    roomType = roomType[0];
    const projectByDesignId: any[] = await getProjects(design.designId.toString());
    let project;
    if (projectByDesignId && projectByDesignId.length > 0) {
        project = projectByDesignId[0];
    }

    let designProducts: any = await getRoomDesigns({ room: design.roomType, style: design.style });

    if(!project) {
        const projectPayload = {
            projectName: `${design.style} ${currentRoomTypeDisplayName.toLowerCase()}`,
            roomModel: { roomType: currentRoomTypeDisplayName.toLowerCase() },
            metaData: { roomType: currentRoomTypeDisplayName.toLowerCase(), styleId: design.style, designId: design.designId, designImage: design.designImage },
            products: designProducts,
            origin: 'DESIGN',
            isInternalUser: typeof localStorage != "undefined" && localStorage.getItem('trafficType') === 'internal'
        }
        project = await createProject(projectPayload);
    }

    return project;
  }


  const handleRoomSelection = async (roomType : any, isSetRoute: boolean = true) => {
    {isAllRecommendationsRequired ? 
      (gtmClickTracking({
        event: 'room_filtered', 
        roomType: roomType.roomType
      })
    ) : (
      gtmClickTracking({
        event: 'Room selected', 
        roomType: roomType.roomType
      })
    )
    }

    setLoaderVisibility(true);
    setRoomType(true);
    const userInfo = await getUserInfo("",true);
    setUserInfo(userInfo);
    const mode = 'lite';
    await getRecommendations(userInfo, roomType.roomType, mode);
    
    setCurrentRoomTypeDisplayName(roomType.roomTypeDisplayName);
    setSelectedRoomType(roomType);
    if(isSetRoute) {
      router.push(`/roomselection/room/${roomType.roomType}`, undefined, { shallow: true });
    }
    setLoaderVisibility(false);

    {isAllRecommendationsRequired ? 
      (gtmPageOnloadTracking({
        event: 'room_designs_page_view',
        pageType: 'Room Designs page'
      })
    ) : (
      gtmPageOnloadTracking({
        event: 'room_designs_page_view',
        pageType: 'Room Designs page'
      })
    )
    }

  };

  const selectAnotherRoomHandler = () => {

    setLoaderVisibility(true);
    setRoomType(false);
    setSelectedRoomType(null);
    setCurrentRoomTypeDisplayName('');
    
  try {
    if(showAllRecommendations) {
      gtmClickTracking({
        event: 'show_all_selected'
      })
        getRecommendations(userInfo);
        return;
    } else {
      navigateAndReplaceHistory(router, '/roomselection');
      gtmClickTracking({
        event: 'Change room selected'
      })
    }
    setRecommendation(null);
  } catch (error) {
      console.error("Error in selectAnotherRoomHandler:", error);
  } finally {
      setLoaderVisibility(false);
  }
  };

  const recommendationImageClickHandler = async (design: any) => {
    if (showAllRecommendations) {
      setSelectedDesign(design);
      handleOverlay(design); // Open overlay
        gtmClickTracking({
          event: 'style_design_selected', 
          design
        });
    } else {
      setSelectedDesign(design);
      handleOverlay(design); // Open overlay
      gtmClickTracking({
        event: 'style_design_selected', 
        design
      });
      return
      // setLoaderVisibility(true);
      // await handleProjectPage(design);
      // handleProjectPage(design);
      
      // gtmClickTracking({
      //   event: 'design_selected', 
      //   design,
  
      // });
    }
    
    if(showAllRecommendations) {
        onImageClickHandler();
        return;
    }
    // setLoaderVisibility(true);
    // await handleProjectPage(design);
    handleProjectPage(design);
  };

    // recommendation API
  const getRecommendations = async(userInfo : any, roomType? : string, mode? : string) => {
    // userInfo.cId = "14ff4be8-44f6-4162-a811-cb6e14b75798";
    // userInfo.cId = "576b42b4-20a7-483c-b01b-61a390581f83";
    setRoomTypesMapLoaded(true);
    let roomTypesEndpoint = `https://nestingale.com/v1/api/recommendations?customerId=${userInfo.cId}`; 
    if(roomType) {
        roomTypesEndpoint += `&roomType=${roomType}`;
    }
    // if (!mode || mode === 'lite') { //for now default to lite if mode is not supplied
    //     mode = 'lite';
    //     roomTypesEndpoint += `&mode=${mode}`;
    // }
    let recommendationPromise = await fetch(roomTypesEndpoint, {
        method: "GET",
        headers: {
        "Content-Type": "application/json",
        },
    });

    let recommendation = await recommendationPromise.json();
    if(recommendation && recommendation.designs) {
      recommendation.designs = recommendation.designs.map((eachRecommendation: any) => {
        eachRecommendation.rows = getRandomInRange(1, 2);
        eachRecommendation.cols = 1;
        return eachRecommendation;
      });
    }
    setRecommendation(recommendation);
  };

  const srcset = (image: string, size: number, rows = 1, cols = 1) => {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  const handleMobileButtonClick = (roomType: string) => {
    setSelectedButton(roomType);
    handleRoomSelection(roomType);
  };

  const categoriesStyles = {
    '--padding-category' : isAllRecommendationsRequired ? '5px' : '20px',
    '--margin-bottom-category' : isAllRecommendationsRequired ? '3px' : '0px'
  } as React.CSSProperties;

  useEffect(() => {
    // Check recommendation and isAllRecommendationsRequired
    if ((!recommendation || Object.keys(recommendation).length === 0) && isAllRecommendationsRequired) {
      setLoaderVisibility(true);  // Show loader
    } else {
      setLoaderVisibility(false); // Hide loader
    }
  }, [recommendation, isAllRecommendationsRequired]);

  // if (!roomTypesMapLoaded) return (<div>loading..</div>)

  const handleOverlayBack = () => {
    setOverlayOpen(true);
    setRoomPlanOnboarding(false);
  };

  const handleCustomerRecentlyViewed = async (design: any) => {
    const recentlyViewedURL = `https://nestingale.com/v1/api/customer/recently-viewed?customerId=${userInfo.cId}&itemId=${design.designId}&type=design`;
  
    try {
      console.log('Posting data to', recentlyViewedURL, 'to track customerRecentlyViewed');
  
      const response = await fetch(recentlyViewedURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: userInfo.cId,
          itemId: design.designId,
          type: 'design',
          name: design.displayName,
          image: design.designImages,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to track recently viewed item: ${response.statusText}`);
      }
  
      console.log('Successfully tracked recently viewed item:',design.designId);
    } catch (error) {
      console.error('Error tracking recently viewed item:', error);
    }
  };
  const [isHideHelpMe, setIsHideHelpMe] = useState(() => isAllRecommendationsRequired ? true : false);
  useEffect(() => {
    function handler() {
      if (window.scrollY > 600 && isAllRecommendationsRequired) {
        setIsHideHelpMe(false)
      } else {
        setIsHideHelpMe(true)
      }
    }
    document.addEventListener('scroll', handler)
    return () => {
      document.removeEventListener('scroll', handler)
    }
  }, [])
  return (
    <>
      <main className="home-container">
        <div className={cssStyles.mainContainer}>
        {/* Title section */}
        {
            !isLoaderVisible && !roomTypeSelected &&
            (<> <div className={cssStyles.marqueeSectionTextContainer}>
                {isAllRecommendationsRequired && <Typography variant="h1" component="h1" className={isAllRecommendationsRequired ? cssStyles.marqueeSectionText3 : cssStyles.marqueeSectionText1}>
                    Designs for you!
                </Typography>}
                <Typography variant="h2" component="h2" className={cssStyles.marqueeSectionText2}>
                {!isAllRecommendationsRequired && (
                  isMobile ? "Select a room to get started" : "Select a room to begin"
                )}
                </Typography>
            </div>
            {(!isMobile) && <hr className={cssStyles.hr} />} </>)
        }

        

        {/* Room types */}
        { 
          !isAllRecommendationsRequired && roomTypeSelected ? 
            (
              <><div className={cssStyles.selectedRoomContainer}>
                  <div className={cssStyles.header}>
                      <Typography variant='h1' component='div'> What do you want your {currentRoomTypeDisplayName} to look like?</Typography>
                  </div> 
                </div> 
               <span  className={cssStyles.selectAnotherRoom} onClick={() => {selectAnotherRoomHandler();}}>Select another room</span>
              </>
            ) : 
            (
                roomTypeSelected ? (
                  isAllRecommendationsRequired ? (
                  !isMobile ? (
                  <div> <hr className={cssStyles.hr2} style={{display:"block", marginTop:"30px"}} />
                  <div className={cssStyles.categoriesContainer}>
                  {roomTypes.map((roomType: any, index: number) =>
                  roomType.status === "active" ? (
                  <div 
                  key={index}
                  className={`${cssStyles.categories}`}
                  onClick={() => {handleRoomSelection(roomType, false); setShowAll(false);}}
                  style={categoriesStyles}>
                  <span className={cssStyles.category}>
                        <div className={cssStyles.category}>
                          <Button variant="contained" className={`${cssStyles.category} ${selectedRoomType === roomType ? cssStyles.selected : ''}`}>
                            {roomType.roomTypeDisplayName.toLowerCase().replace(/(^\w|\s\w)/g, (m: string) => m.toUpperCase())}
                          </Button>
                        </div>
                  </span>
                </div>
              ) : null
              )}

              {/* Show All Button */}
              <Button variant="contained" onClick={selectAnotherRoomHandler} className={cssStyles.showAll} style={{ marginLeft: "5px" }}>
                  Show All
              </Button>

              {/* <hr className={cssStyles.hr} /> */}
            </div>
            <hr className={cssStyles.hr2} style={{display:"block"}} />
            </div>
            ) : (
              <><div className={cssStyles.marqueeSectionTextContainer}>
                    <Typography variant="h1" component="h1" className={isAllRecommendationsRequired ? cssStyles.marqueeSectionText3 : cssStyles.marqueeSectionText1}>
                      Designs for you!
                    </Typography>
                    <Typography variant="h2" component="h2" className={cssStyles.marqueeSectionText2}>
                    {!isAllRecommendationsRequired && (
                  isMobile ? "Select a room to get started" : "Select a room to begin"
                )}
                    </Typography>
                  </div><div className={cssStyles.mobileButtonContainer}>
                      {roomTypes.map((roomType: any, index: number) => roomType.status === "active" ? (
                        <Button
                          key={index}
                          variant="contained"
                          className={`${cssStyles.mobileButton} ${selectedRoomType === roomType ? cssStyles.selected : cssStyles.mobileButton}`}
                          onClick={() => handleMobileButtonClick(roomType)}
                          style={{
                            color: selectedRoomType === roomType ? '#00a99d' : '#606060',
                            border: selectedRoomType === roomType ? '1px solid #00a99d' : '1px solid #606060', // Border is always visible, changes based on selection
                          }}
                        >
                          {roomType.roomTypeDisplayName.toLowerCase().replace(/(^\w|\s\w)/g, (m: string) => m.toUpperCase())}
                        </Button>
                      ) : null
                      )}

                      <Button
                        variant="contained"
                        onClick={() => {
                          selectAnotherRoomHandler();
                          setSelectedRoomType(null); // Reset room type selection to null
                          setShowAll(true); // Handle logic for showing all recommendations
                        }}
                        className={cssStyles.mobileButton}
                        style={{
                          color: selectedRoomType === null ? '#00a99d' : '#606060', // Turns green when Show All is clicked
                          border: selectedRoomType === null ? '1px solid #00a99d' : '1px solid #606060', // Border turns green when selected
                        }}
                      >
                        Show All
                      </Button>
                    </div></>
            )
          ) : (
            // If recommendations are not required, prompt to select another room.
            <>
              <div className={cssStyles.selectedRoomContainer}>
                <div className={cssStyles.header}>
                  <Typography variant="h1" component="div">
                    What do you want your {currentRoomTypeDisplayName} to look like?
                  </Typography>
                </div>
              </div>
              <span className={cssStyles.selectAnotherRoom} onClick={selectAnotherRoomHandler}>
                Select another room
              </span>
            </>
          )
        ) : (
          // If no room type is selected, handle based on the `isAllRecommendationsRequired` flag.
          isAllRecommendationsRequired ? (
            !isMobile ? (
            <div className={cssStyles.categoriesContainer}>
              {/* Display active room types with buttons */}
              {roomTypes.map((roomType: any, index: number) =>
                roomType.status === "active" ? (
                  <div
                    key={index}
                    className={cssStyles.categories}
                    onClick={() => handleRoomSelection(roomType, false)}
                    style={categoriesStyles}
                  >
                    <span className={cssStyles.category}>
                      {!isAllRecommendationsRequired ? (
                        <>
                          <img src={roomType.smallIcon} alt={roomType.roomTypeDisplayName} />
                          <p>{roomType.roomTypeDisplayName}</p>
                        </>
                      ) : (
                        <div className={cssStyles.category}>
                          <Button variant="contained" className={cssStyles.category}>
                            {roomType.roomTypeDisplayName.toLowerCase().replace(/(^\w|\s\w)/g, (m: string) => m.toUpperCase())}
                          </Button>
                        </div>
                      )}
                    </span>
                  </div>
                ) : null
              )}
              {/* Show All Button */}
              <Button variant="contained" onClick={selectAnotherRoomHandler} className={cssStyles.showAll} style={{ marginLeft: "5px" }}>
                  Show All
              </Button>
              </div>
            ) : (

              <div className={cssStyles.mobileButtonContainer}>
              {roomTypes.map((roomType: any, index: number) =>
                roomType.status === "active" ? (
                  <Button
                    key={index}
                    variant="contained"
                    className={`${cssStyles.mobileButton} ${selectedRoomType === roomType ? cssStyles.selected : cssStyles.mobileButton}`}
                    onClick={() => handleMobileButtonClick(roomType)}
                    // onTouchStart={() => handleMobileButtonClick(roomType)}
                    style={{
                      color: selectedRoomType === roomType ? '#00a99d' : '#606060',
                      border: selectedRoomType === roomType ? '1px solid #00a99d' : '1px solid #606060', // Border is always visible, changes based on selection
                    }}
                  >
                    {roomType.roomTypeDisplayName.toLowerCase().replace(/(^\w|\s\w)/g, (m: string) => m.toUpperCase())}
                  </Button>
                ) : null
              )}

              <Button
                variant="contained"
                onClick={() => {
                  selectAnotherRoomHandler();
                  setSelectedRoomType(null); // Reset room type selection to null
                  setShowAll(true); // Handle logic for showing all recommendations
                }}
                className={cssStyles.mobileButton}
                style={{
                  color: selectedRoomType === null ? '#00a99d' : '#606060', // Turns green when Show All is clicked
                  border: selectedRoomType === null ? '1px solid #00a99d' : '1px solid #606060', // Border turns green when selected
                }}
              >
                Show All
              </Button>
            </div>
            )
          ) : ( <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px'  }} component="div" >
            <Box sx={{ display: 'flex', justifyContent: 'space-around' , flexWrap: { xs: 'wrap', md: 'nowrap'},  width: isMobile ? '88%' : 'inherit', }} component="div">
                {roomTypes.map((roomType: any, index: number) =>
                  (
                    <Box component="div" sx={{ 
                          display: 'flex', 
                          flex: { xs: '0 1 calc(50% - 16px)', sm: '0 1 calc(50% - 16px)', md: '0 1 calc(50% - 16px)', lg: '1' }, 
                          width: {lg: '150px'}, 
                          justifyContent: 'center', 
                          margin: { xs: '0 0',  sm: '0 0', md: '0 10px', lg: '0 10px' } 
                        }} 
                        key={index} onClick={roomType.status !== 'pending' ? () => handleRoomSelection(roomType) : undefined}>
                      <Typography  component="span" className={`${cssStyles.category} ${roomType.status === 'pending' ? cssStyles.pending : ''}`}>
                          <Box component="div" sx={{ position: 'relative' }} className={cssStyles.imageWrapper}>
                            <Box sx={{ 
                                  width: { xs: '140px', sm: '100px', md: '100px', lg: '100px' }, 
                                  height: { xs: '140px', sm: '100px', md: '100px', lg: '100px' } 
                                }} 
                                component="img" src={roomType.smallIcon} alt={roomType.roomTypeDisplayName} />
                            {roomType.status === 'pending' && <div className={cssStyles.overlayText}>Coming Soon</div>}
                          </Box>
                          <Typography sx={{ marginTop: '5px' }} component="p">{roomType.roomTypeDisplayName}</Typography>
                      </Typography>
                    </Box>
                  )
                )}
            </Box>
          </Box>
          )
        )
        )
      }


        {!roomTypeSelected && !isMobile && <hr className={cssStyles.hr} />}
        
        { (!showAllRecommendations) && (!roomTypeSelected) && (!isMobile) ? <div style= {{display: "flex", width: "100%",justifyContent: "center"}}>
            <img src="/assets/img/selectRoom.png" alt="Room Image" width="100%" /> 
        </div> : null}

        {loading ? (
          <div className={cssStyles.loading}> Loading ... </div>
        ) : 

        (!isLoaderVisible && (showAll || recommendation?.designs) && (
        <Box component="div" sx={{ width: '100%', height: '100% !important', overflowY: 'scroll', position: 'relative' }}>
          <ImageList
            variant="quilted" 
            cols={isLargeDesk ? 4 : !isMobile ? 3 : 2} 
            gap={isMobile ? 10 : 25} 
            rowHeight={isMobile ? 200 : 260}
            sx = {{padding: '2%'}}
            >
            {recommendation.designs.map((design: any, index: number) => {
              
              // Handle alternating rows
              let cols, rows;

              if (isMobile) {
                // Mobile logic: 2 columns, alternate row height per column
                cols = 1; // Each image takes 1 column on mobile
                rows = index % 2 === 0 ? 2 : 1; // Alternate between 2 rows and 1 row
              } else {
                // Desktop logic: 3 or 4 columns, alternate row height per column
                cols = 1; // Each image takes 1 column on desktop
                rows = index % 2 === 0 ? 2 : 1; // Alternate between 2 rows and 1 row
              }

              // Handle when designImages is a single string (image URL)
              if (typeof design.designImages === 'string') {
                return (
                  <ImageListItem key={design.designId} cols={cols} rows={rows}>
                    <img
                      {...srcset(design.designImages, 121, rows, cols)}
                      alt={design.displayName}
                      className={cssStyles.designImages}
                      style={{ borderRadius: "15px", cursor:"pointer" }}
                      loading="lazy"
                      onClick={() => {
                        recommendationImageClickHandler(design);
                        handleCustomerRecentlyViewed(design)
                      }}
                    />
                  </ImageListItem>
                );
              }
              
              return null; // Return null if designImages is not valid
            })}
            </ImageList>

            <div className={cssStyles.helpMeContainer}>
                {recommendation?.designs && !isHideHelpMe&&(<HelpMe onClickHandler = {routeToRoomQuiz}/>)}
            </div>
        </Box>
        ))}

        {selectedDesign && (
          <DesignOverlay
          open={overlayOpen}
          onClose={() =>{ setOverlayOpen(false)
            setSelectedDesign(null)
          }}
          design={selectedDesign}
          onStartDesign={() =>{
            setRoomPlanOnboarding(true)
             setOverlayOpen(false)
          }}
          recommendations={recommendationDesignsBasedOnSelectedDesign}
          handleRecommendationChange={(design:any) => handleRecommendationChange(design)}
          userInfo={userInfo}
          showFavFromFavList={showFavFromFavList}
          ontoggleFav={ontoggleFav}
        />
        )}

        {roomPlanOnboarding && (
          <RoomPlanOnboarding roomTypeDisplayName = {currentRoomTypeDisplayName} designImage={selectedDesign} onCloseOverlay={()=>{setRoomPlanOnboarding(false)}} currentRoomType = {selectedDesign?.roomType} handleOverlayBack={handleOverlayBack} />
        )}

      {isLoaderVisible && (
            <Box
            component="div"
            sx={{ width: '100%', height: '100% !important', overflowY: 'scroll', position: 'relative' }}
          >
            <ImageList
              variant="quilted"
              cols={isLargeDesk ? 4 : !isMobile ? 3 : 2}
              gap={isMobile ? 10 : 25}
              rowHeight={isMobile ? 200 : 260}
              sx={{ padding: '2%' }}
            >
              {/* Dummy data for loading effect */}
              {Array.from(new Array(12)).map((_, index: number) => {
                let cols, rows;
        
                if (isMobile) {
                  cols = 1;
                  rows = index % 2 === 0 ? 2 : 1;
                } else {
                  cols = 1;
                  rows = index % 2 === 0 ? 2 : 1;
                }
        
                return (
                  <ImageListItem key={index} cols={cols} rows={rows}>
                    <Skeleton
                      variant="rectangular"
                      width={isLargeDesk ? `${375 * cols}px` : '100%' }
                      height={isLargeDesk ? `${265 * rows}px`: '100%'}
                      sx={{ borderRadius: '15px' }}
                    />
                  </ImageListItem>
                );
              })}
            </ImageList>
          </Box>
      )}

      </div>
      </main>
      
    </>
  );
};

export default RoomSelection;
