import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Image from 'next/image';
import { trackItem } from "@/utils";
import cssStyles from "./cms_renderer.module.scss";
import ReactMarkdown from 'react-markdown'
import { prepareCardsForCategoriesGrid } from '@/translator';
//TODO : need to refactor the existing product_cards.tsx to replace below shop_product_cards.tsx
import generateProductCards from "@/components/products/shop_product_cards";
import CommonCarouselComponent from "@/components/carousal/common_carousel";
import GenerateCategoryCard from "@/components/products/category_cards";
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { CardData, getProductsByIds } from "@/store/productSlice";
import { prepareCardsForBuilderProductsGrid } from '@/translator';
import ReactMultiCarousel from "react-multi-carousel";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

//import jsonObj from "/Users/rajendarmunjam/workspace/cms/stage/category/gifts.json";
//import jsonObj from "/Users/rajendarmunjam/workspace/cms/stage/category/beds.json";
//import jsonObj from "/Users/rajendarmunjam/workspace/cms/stage/category/sofas.json";
//import jsonObj from "/Users/rajendarmunjam/workspace/cms/stage/category/livingroom.json";
//import jsonObj from "/Users/rajendarmunjam/workspace/cms/stage/category/newshop.json";


interface SectionData {
    backgroundImageUrl?: string;
    buttons?: SectionData[];
    color?: string;
    content?: string | string[];
    description?: string | string[];
    footer?: SectionData;
    gridItems?: SectionData[];
    header?: SectionData;
    id?: string;
    imagePanel?: SectionData;
    imageUrl?: string;
    imageUrls?: string[];
    items?: SectionData[] | string[];
    link?: string;
    name?: string;
    newTab?: boolean;
    order?: string[];
    panels?: SectionData[];
    para?: string;
    subTitle?: string;
    text?: string | string[];
    title?: string;
    space?: string;
    trackId?: string;
    trackInfo?: {
        event?: string;
        section?: string;
    };
    preProcess?: {
        method: string;
        input: any;
    };
    type?: string;
    uiCompType?: UICompType;
}

interface UICompType {
    method?: string;
    options?: {
        align?: string;
        color?: string;
        columns?: number;
        component?: string;
        display?: string;
        gutterBottom?: string;
        height?: string;
        imageWidth?: string;
        imageHeight?: string;
        textWidth?: string;
        textHeight?: string;
        swapItems?: boolean;
        minHeight?: number;
        noWrap?: boolean;
        parasCount?: number;
        shape?: string;
        size?: string;
        splitRaito?: number;
        sx?: string;
        width?: string;
        variant?: string;
        markdown?: boolean;
        grid?: string;
        spacing?: number;
        objectFit?: string;
        carouselProps?: any;
        xs?: number;
    }
    className?: string;

    styles?: React.CSSProperties;
    sectionStyles?: React.CSSProperties;
    parentStyles?: React.CSSProperties;
    containerStyles?: React.CSSProperties;
    imageContainerStyles?: React.CSSProperties;
    imageStyles?: React.CSSProperties;
}

interface PageData {
    page: string;
    title: string;
    env: string;
    order: string[];
    sections: {
        [key: string]: SectionData;
    };
}

const CMS_HOST = process.env.CMS_HOST;
let CMS_ID_COUNTER = 0;
export type RenderFunctionType = {
    [key: string]: (sectionData: any, props?: any, index?: number) => JSX.Element | null;
};

export function Accordion(sectionData: any): JSX.Element | null {
    console.log("Accordion...");
    const panelClassName = "accordion";

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <>
            {sectionData.title && <Title {...sectionData} />}
            {sectionData.description && <Typography variant="body1" className={cssStyles[panelClassName + "Description"]}>
                {transformDescription(sectionData.description, "", sectionData.trackId)}
            </Typography>}
            <div className={cssStyles[panelClassName + "Container"]}>
                {sectionData.items && sectionData.items.map((item: any, index: number) => (
                    <MuiAccordion key={index} expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        sx={{ boxShadow: 'none', '&:before': { 'opacity': '1 !important' }, '&.Mui-expanded': { margin: '0px !important' } }}
                        className={cssStyles[panelClassName + "AccordionItem"]}>
                        <AccordionSummary
                            expandIcon={expanded === `panel${index}` ? <RemoveIcon /> : <AddIcon />}
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                            className={cssStyles["AccordionSummary"]}
                            sx={{
                                minHeight: 'initial !important',
                                '.Mui-expanded': {
                                    margin: '10px 0 !important'
                                }
                            }}
                        >
                            <strong>{item.title}</strong>
                        </AccordionSummary>
                        <AccordionDetails className={cssStyles["AccordionDetails"]}>
                            {transformDescription(item.description, undefined, item.trackId)}
                        </AccordionDetails>
                    </MuiAccordion>
                ))}
            </div>
        </>
    );
}

export function CustCarousel(sectionData: any, props: any): JSX.Element | null {
    console.log("Carousel...");
    const { vendorName, userInfo, favorites } = props;
    const panelClassName = "carousel";
    return (
        <>
            {sectionData.title && <Title {...sectionData} />}
            <Grid container className={cssStyles[panelClassName + "Container"]}>
                <CommonCarouselComponent fullWidth={true} type="scrollable"
                    cards={sectionData.items.map((item: any) => (
                        <Grid key={item.name} item>{item}</Grid>
                    ))} />
            </Grid>
        </>
    );
}

export function CategoriesCarousel(sectionData: any, props: any): JSX.Element | null {
    console.log("CategoriesCarousel...");
    const { vendorName, userInfo, favorites } = props;
    const panelClassName = "carousel";
    const items = GenerateCategoryCard(
        prepareCardsForCategoriesGrid(sectionData.items as CardData[], getBrandPathEntry(vendorName), vendorName),
        handleProductSelection,
        (sectionData.uiCompType?.options?.height || "320"),
        (sectionData.uiCompType?.options?.width || "320"),
        sectionData.trackId || "CategoryCarouselCards",
    );
    return CustCarousel({ ...sectionData, items }, props);
}

export function CirclerImageButton(sectionData: any, props: any, index: number = 1): JSX.Element | null {
    console.log("CirclerImageButton...");
    const baseClassName = "imgButtonCircular";
    const styles = sectionData.uiCompType?.styles || {};
    const title = sectionData.title || sectionData.name || sectionData.text;
    return (
        <div key={(sectionData.trackId || CMS_ID_COUNTER++) + index} className={cssStyles[baseClassName]} style={styles}>
            <div
                className={cssStyles[`${baseClassName}__outerCircle`]}
                onClick={(e) => { trackItem(sectionData.trackInfo || {}); }}
            >
                <div
                    className={cssStyles[`${baseClassName}__innerCircle`]}
                    style={{
                        height: sectionData.uiCompType?.options?.height || 200,
                        width: sectionData.uiCompType?.options?.width || 200,
                    }}
                >
                    <div
                        className={cssStyles[`${baseClassName}__circle`]}
                        style={{ backgroundImage: `url(${sectionData.imageUrl})` }}
                    >
                    </div>
                </div>
            </div>

            {title && renderFunctions.CustText({
                text: title,
                uiCompType: {
                    ...sectionData.uiCompType,
                    options: {
                        markdown: true,
                        ...sectionData.uiCompType?.options
                    },
                    styles: {
                        ...sectionData.uiCompType?.styles,
                    }
                }
            }, props)
            }

        </div>
    );
}

export function extractStylesFromUrl(urlStr: string) {
    try {

        const url = new URL(urlStr);
        const params = new URLSearchParams(url?.hash?.substring(1));
        const stylesFromUrl = Object.fromEntries(params.entries());
        url.hash = '';
        return { 'urlStr': url.toString(), 'stylesFromUrl': stylesFromUrl };
    } catch (error) {
        console.info("invalid URL:", error);
        return { 'urlStr': urlStr, 'stylesFromUrl': {} };
    }
}

/**
 * Renders a content section with an optional background image.
 *
 * @param sectionData - The data for the section, including content and styles.
 * @param props - Additional properties to pass to the content panel.
 * @returns A JSX element containing the content and background image, or null if no content is provided.
 *
 * @remarks
 * - The component applies styles from `sectionData.uiCompType?.styles` to the container.
 * - If `sectionData.content` is provided, it renders the content using the `Panel` component.
 * - If `sectionData.backgroundImageUrl` is provided, it renders an `Image` component with the specified URL.
 *
 * @example
 * ```tsx
 * const sectionData = {
 *   uiCompType: { styles: { backgroundColor: 'red' } },
 *   content: { text: 'Hello, World!' },
 *   backgroundImageUrl: '/path/to/image.jpg',
 *   title: 'Sample Title'
 * };
 * 
 * <ContentOnImage sectionData={sectionData} props={{}} />
 * ```
 */
export function ContentOnImage(sectionData: any, props: any): JSX.Element | null {
    console.log("ContentOnImage...");
    const panelClassName = "contentOnImage";

    const renderContentOnImage = () => (
        <div className={cssStyles[panelClassName + "Container"]} style={sectionData.uiCompType?.styles}>
            {sectionData.content && (
                <div className={cssStyles[panelClassName + "Content"]} style={sectionData.content?.uiCompType?.parentStyles}>
                    {Panel(sectionData.content as any, props)}
                </div>
            )}
            {sectionData.backgroundImageUrl && (
                <Image
                    alt={sectionData.title || ''}
                    src={sectionData.backgroundImageUrl}
                    layout="fill"
                    objectFit="cover"
                />
            )}
        </div>
    );

    return sectionData.link ? (
        <a
            id={sectionData.trackId || ('link_' + CMS_ID_COUNTER++)}
            href={sectionData.link}
            target={sectionData.newTab ? "_blank" : "_self"}
            onClick={() => {
                trackItem(sectionData.trackInfo || {});
            }}
        >
            {renderContentOnImage()}
        </a>
    ) : (
        renderContentOnImage()
    );

}


export function CustText(sectionData: any): JSX.Element | null {
    console.log("CustText...");
    const styles = sectionData.uiCompType?.styles || {};

/*     const renderTypography = () => (
        <Typography
            {...(sectionData.uiCompType?.options?.variant && { variant: sectionData.uiCompType?.options?.variant })}
            {...(sectionData.uiCompType?.options?.component && { component: sectionData.uiCompType?.options?.component })}
            {...(sectionData.uiCompType?.options?.align && { align: sectionData.uiCompType?.options?.align })}
            {...(sectionData.uiCompType?.options?.color && { color: sectionData.uiCompType?.options?.color })}
            {...(sectionData.uiCompType?.options?.display && { display: sectionData.uiCompType?.options?.display })}
            {...(sectionData.uiCompType?.options?.gutterBottom && { gutterBottom: sectionData.uiCompType?.options?.gutterBottom })}
            {...(sectionData.uiCompType?.options?.noWrap && { noWrap: sectionData.uiCompType?.options?.noWrap })}
            {...(sectionData.uiCompType?.options?.sx && { sx: sectionData.uiCompType?.options?.sx })}
            {...(sectionData.uiCompType?.className && { className: cssStyles[sectionData.uiCompType.className] })}
            style={styles}
        >
            {transformDescription(sectionData.text || '', "", (sectionData.trackId || ''), styles)}
        </Typography>
    ); */

    return (
        <>
            {sectionData.text &&              
            transformDescription(sectionData.text || '', 
                sectionData.uiCompType.className, 
                (sectionData.trackId || CMS_ID_COUNTER++), 
                styles, 
                sectionData.uiCompType?.options?.component || sectionData.uiCompType?.options?.variant)
            }
        </>
    );
}

const parseDimension = (dimension?: string | number | undefined): number | undefined => {
    if (typeof dimension === 'string') {
        return parseInt(dimension.replace('px', ''), 10);
    }
    return dimension;
};

export function CustButton(sectionData: any, index: number = 1): JSX.Element | null {
    console.log("CustButton...");
    const panelClassName = "custButton";
    const button = getButton(sectionData, index);

    return sectionData.uiCompType?.parentStyles ? (
        <div style={{ ...sectionData.uiCompType?.parentStyles }}>
            {button}
        </div>
    ) : (
        <span>
            {button}
        </span>
    );
}
/**
 * Renders a custom image component with optional link and tracking.
 *
 * @param {any} sectionData - The data for the section containing image information and styles.
 * @param {number} [index=1] - The index of the image, default is 1.
 * @returns {JSX.Element | null} The rendered image component or null.
 *
 * @example
 * // Example sectionData structure:
 * {
 *   imageUrl: "https://example.com/image.jpg",
 *   title: "Example Image",
 *   link: "https://example.com",
 *   newTab: true,
 *   trackId: "image1",
 *   trackInfo: { ... },
 *   uiCompType: {
 *     styles: { width: "100px", height: "100px" },
 *     containerStyles: { ... },
 *     parentStyles: { ... },
 *     options: { objectFit: "cover" }
 *   }
 * }
 *
 * @example
 * // Example usage:
 * <CustImage sectionData={sectionData} index={2} />
 */
export function CustImage(sectionData: any, index: number = 1): JSX.Element | null {
    console.log("CustImage...");
    const { stylesFromUrl, urlStr } = extractStylesFromUrl(sectionData.imageUrl as string);
    const imageStyles = { ...sectionData.uiCompType?.styles, ...stylesFromUrl };
    const imageContainerStyles = { ...sectionData.uiCompType?.containerStyles, ...sectionData.uiCompType?.parentStyles };

    // moving imageStyles to ContainerStyles when the contianerStyles does not have height or width
    if (!imageContainerStyles.height && !imageContainerStyles.width) {
        if (imageStyles.width) {
            imageContainerStyles.width = `${parseDimension(imageStyles.width as string | number)}px`;
            delete imageStyles.width;
        }

        if (imageStyles.height) {
            imageContainerStyles.height = `${parseDimension(imageStyles.height as string | number)}px`;
            delete imageStyles.height;
            if (!imageContainerStyles.width) {
                imageContainerStyles.width = '100%';
            }
        }
    }

    const image = (
        (imageStyles.width && imageStyles.height) ? (
            <Image
                alt={sectionData.title || sectionData.imageUrl || ''}
                src={urlStr as string}
                width={parseDimension(imageStyles.width as string)}
                height={parseDimension(imageStyles.height as string)}
                style={imageStyles}
                className={cssStyles.image}
            />
        ) : (
            <Image
                alt={sectionData.title || urlStr || ''}
                src={urlStr as string}
                fill={true}
                layout="fill"
                objectFit={sectionData.uiCompType?.options?.objectFit || imageStyles.objectFit as string || "cover"}
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                quality={75}
                style={imageStyles}
                onError={(e) => console.error("Failed to load Image : " + urlStr)}
                className={cssStyles.image}
            />
        )
    );

    return (
        <div>
            {sectionData.link ? (
                <a id={ sectionData.trackId || 'image_' + CMS_ID_COUNTER++ }  href={sectionData.link} target={sectionData.newTab ? "_blank" : "_self"}
                    onClick={() => { trackItem(sectionData.trackInfo || {}); }}
                >
                    <div key={sectionData.trackId as string + index} className={cssStyles.imageContainer} style={{ position: 'relative', ...imageContainerStyles }}>
                        {image}
                    </div>
                </a>
            ) : (
                <div key={(sectionData.trackId || CMS_ID_COUNTER++) + index} className={cssStyles.imageContainer} style={{ position: 'relative', ...imageContainerStyles }}>
                    {image}
                </div>
            )}
        </div>
    );
}



export const ExpandableContent: React.FC<{ maxHeight: number; children: React.ReactNode }> = ({ children, maxHeight }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
        setExpanded(!expanded);
        if (expanded && wrapperRef.current) {
            //wrapperRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [showExpanderLink, setShowExpanderLink] = useState(false);
    const contentRef = React.useRef<HTMLDivElement>(null);
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (contentRef.current) {
            setShowExpanderLink(contentRef.current.scrollHeight > maxHeight);
        }
    }, [maxHeight, children]);

    return (
        <div style={{ border: '0px solid red' }} id="expanderWrapper" ref={wrapperRef}>
            <div
                id="expanderContent"
                ref={contentRef}
                style={{
                    maxHeight: expanded ? `${contentRef.current?.scrollHeight}px` : `${maxHeight}px`,
                    overflow: 'hidden',
                    //border: '1px solid green',
                    transition: 'max-height 0.5s ease-in-out',
                }}
            >
                {children}
            </div>
            {showExpanderLink && (
                <div
                    id="expanderLink"
                    style={{
                        textAlign: 'right',
                        //marginTop: '-10px',
                        //borderTop: '1px solid black',
                    }}
                >
                    <a
                        onClick={toggleExpand}
                        style={{
                            cursor: 'pointer',
                            color: 'black',
                            fontWeight: 'bold',
                            textDecoration: 'none',
                        }}
                    >
                        {expanded ? '...Less -' : '... More +'}
                    </a>
                </div>
            )}
        </div>
    );
};


const getButton = (sectionData: any, index: number) => (
    <Button
        key={(sectionData.trackId || ('Button_'+ (index ?? CMS_ID_COUNTER++))) }
        id={(sectionData.trackId || ('Button_'+ (index ?? CMS_ID_COUNTER++))) }
        color={sectionData.color || sectionData.uiCompType?.options?.color || "primary"}
        href={sectionData.link}
        target={sectionData.newTab || sectionData.uiCompType?.options?.newTab ? "_blank" : "_self"}
        onClick={() => {
            trackItem(sectionData.trackInfo || {});
            if (sectionData.preProcess?.method) {
                // @ts-ignore
                if (typeof window[sectionData.preProcess.method] === 'function') {
                    // @ts-ignore
                    window[sectionData.preProcess.method](sectionData.preProcess.input);
                }
            }
        }}
        style={{ ...sectionData.uiCompType?.styles }}
        className={cssStyles[sectionData.uiCompType?.className || "custButton"]}
        size={sectionData.uiCompType?.options?.size || "small"}
    >
        {sectionData.title}
    </Button>
);

const handleProductSelection = (cardIndex: any, event?: any, card?: any, sectionName?: string) => {
    console.log('Selected cardIndex: ' + cardIndex + "cardId : " + JSON.stringify(card) + "sectionName : " + sectionName);
    trackItem({
        id: card.id,
        event: sectionName + 'Click',
        name: card.name,
        section: sectionName
    });
}


export function ImageButton(item: any, props: any, index: number = 1): JSX.Element | null {
    console.log("ImageButton...");
    return item.link
        ? <a key={item.trackId + index} href={item.link}
            id={item.trackId || 'imageButton_' + CMS_ID_COUNTER++}
            target={item.newTab ? "_blank" : "_self"}
            onClick={() => { trackItem(item.trackInfo) }}
        >
            {CirclerImageButton(item, props, index)}
        </a>
        : CirclerImageButton(item, props, index);
}

export function ItemsGrid(sectionData: any, props: any): JSX.Element | null {
    console.log("ItemsGrid...");
    const styles = { ...sectionData.uiCompType?.styles };
    delete sectionData.uiCompType?.styles;
    const items = sectionData.items;
    const swapItemsClass = sectionData.uiCompType?.options?.swapItems ? cssStyles.swapItems : '';
    const isCenterGrid = sectionData.uiCompType?.options?.grid === 'center';
    
    return (
        <>
            {sectionData.uiCompType?.options?.grid === 'flex' ? (
            <div style={{ display: 'flex', flexWrap: 'wrap', ...styles }} className={`${cssStyles.gridContainer} ${swapItemsClass}`}>
                {items?.map((item: any, index: number) => (
                <div
                    key={index}
                    style={{
                    flex: `1 0 ${item.uiCompType?.options?.width ?? `${100 / items.length}%`}`,
                    boxSizing: 'border-box',
                    padding: item.uiCompType?.options?.spacing || '8px',
                    ... item.uiCompType?.parentStyles
                    }}
                >
                    {renderSection(item.trackId, item, props)}
                </div>
                ))}
            </div>
            ) : (
            <Grid container 
                justifyContent={isCenterGrid ? 'center' : undefined} 
                spacing={sectionData.uiCompType?.options?.spacing ?? 2} 
                className={`${cssStyles.gridContainer} ${swapItemsClass}`} style={styles}>
                {items?.map((itemObj: any, index: number) => (
                <Grid item className={cssStyles.gridItem}
                    style={{ ...itemObj.uiCompType?.styles }}
                    xs={itemObj?.uiCompType?.options?.xs ?? (isCenterGrid ? 'auto' : 12)}
                    md={itemObj?.uiCompType?.options?.md ?? ( isCenterGrid ? 'auto' : (12 / items.length))}
                    lg={itemObj?.uiCompType?.options?.lg ?? ( isCenterGrid ? 'auto' : (12 / items.length))}
                    key={index}>
                    {renderSection(itemObj.trackId, itemObj, props)}
                </Grid>
                ))}
            </Grid>
            )}
        </>
    );
}

/**
 * Renders a Markdown text panel with expandable content.
 *
 * @param sectionData - The data for the section to be rendered.
 * @param sectionData.trackId - The track ID for the section.
 * @param sectionData.items - The items to be rendered, either a string or an array of strings.
 * @param sectionData.uiCompType?.options - Options for the UI component type.
 * @param sectionData.uiCompType?.options.parasCount - The number of paragraphs to show before the "Show More" link.
 * @param sectionData.uiCompType?.options.minHeight - The minimum height for the expandable content.
 * @param sectionData.uiCompType?.styles - Styles to be applied to the content.
 *
 * @returns A JSX element representing the Markdown text panel, or null if the items are not provided.
 */
export function MarkdownTextPanel(sectionData: any): JSX.Element | null {
    console.log("MarkdownTextPanel...");
    const trackId = sectionData.trackId || "markdownTextPanel_" + CMS_ID_COUNTER++;
    const items = sectionData.items as string[] | string;
    const { parasCount, minHeight } = sectionData.uiCompType?.options || {};
    const styles = sectionData.uiCompType?.styles || {};

    const panelClassName = trackId ? trackId + "_sectionDescription" : "sectionDescription";
    const [showMore, setShowMore] = React.useState(false);

    if (typeof items === "object") {
        if (minHeight) {
            return (
                <ExpandableContent maxHeight={minHeight}>
                    {items.map((descItem: string, index: number) => transformTextToHtml(descItem, panelClassName, trackId + index, styles))}
                </ExpandableContent>
            );
        } else if (parasCount) {
            const showMoreParaCount = isNaN(parasCount) ? 1 : parasCount;
            const firstPart = items.slice(0, showMoreParaCount);
            const secondPart = items.slice(showMoreParaCount);

            return (
                <>
                    <div style={{ ...styles }}>
                        {firstPart.map((descItem: string, index: number) => {
                            let transformedText = transformTextToHtml(descItem, panelClassName, trackId + index);
                            if (!showMore && index + 1 === firstPart.length) {
                                transformedText = React.createElement(
                                    React.Fragment,
                                    {},
                                    (transformedText as React.ReactElement).props.children,
                                    <a key={`showMore-${trackId}`} href="#" onClick={(e) => { e.preventDefault(); setShowMore(true); }}
                                        style={{ fontWeight: 'bold', textDecoration: 'none', marginLeft: '5px', color: 'black' }}>
                                        ...More
                                    </a>
                                );
                            }
                            return transformedText;
                        })}
                    </div>
                    {showMore && (
                        <div style={{ ...styles }}>
                            {secondPart.map((descItem: string, index: number) => {
                                let transformedText = transformTextToHtml(descItem, panelClassName, trackId + showMoreParaCount + index);
                                if (index + 1 === secondPart.length) {
                                    transformedText = React.createElement(
                                        React.Fragment,
                                        {},
                                        (transformedText as React.ReactElement).props.children,
                                        <a key={`showLess-${trackId}`} href="#" onClick={(e) => { e.preventDefault(); setShowMore(false); }}
                                            style={{ fontWeight: 'bold', textDecoration: 'none', marginLeft: '5px', color: 'black' }}>
                                            ...Less
                                        </a>
                                    );
                                }
                                return transformedText;
                            })}
                        </div>
                    )}
                </>
            );
        } else {
            return (
                <>
                    {items.map((descItem: string, index: number) => (
                        transformTextToHtml(descItem, panelClassName, trackId + index, styles)
                    ))}
                </>
            );
        }
    } else {
        return transformTextToHtml(items, panelClassName, trackId, styles);
    }
}


function extractKeyStyles(styleParts: string[]): React.CSSProperties {
    const keyStyles = styleParts.reduce((acc, curr) => {
        if (curr.match(/^(left|center|right|justify|start|end)$/)) {
            acc.textAlign = curr as React.CSSProperties['textAlign'];
        } else if (curr.match(/^(small|medium|large|\d+px|\d+em|\d+rem)$/)) {
            acc.fontSize = curr;
        } else if (curr.match(/^(#(?:[0-9a-fA-F]{3}){1,2}|red|blue|green|black|white)$/)) {
            acc.color = curr;
        } else if (curr.match(/^(bold|normal|light|\d{3})$/)) {
            acc.fontWeight = curr as React.CSSProperties['fontWeight'];
        }
        return acc;
    }, {} as React.CSSProperties);
    return keyStyles;
}

const renderText = (text: string | string[], className: string, 
        uiCompType: any, props: any, variant?: string, extractedStyles?: React.CSSProperties) => {
   
    if(!text) return;
    return renderFunctions.CustText({
        text,
        uiCompType: {
            className,
            ...uiCompType,
            options: {
                variant,
                ...uiCompType?.options,
            },
            styles: {
                ...uiCompType?.styles,
                ...extractedStyles,
            }
        }
    }, props);
};
/**
 * Renders a Panel component based on the provided section data.
 *
 * @param sectionData - The data object containing information about the section to be rendered.
 * @param sectionData.trackId - Optional unique identifier for the panel.
 * @param sectionData.uiCompType - Object containing UI component type information.
 * @param sectionData.uiCompType.styles - Optional styles for the panel.
 * @param sectionData.space - Optional space value to render a spacer div.
 * @param sectionData.h1 - Optional data to render an h1 element.
 * @param sectionData.title - Optional data to render a title (h2) element.
 * @param sectionData.subTitle - Optional data to render a subtitle (h3) element.
 * @param sectionData.text - Optional data to render a text element.
 * @param sectionData.description - Optional data to render a description element.
 * @param sectionData.imageUrl - Optional URL to render an image.
 * @param sectionData.link - Optional link associated with the image.
 * @param sectionData.imagePanel - Optional data to render a custom image panel.
 * @param sectionData.button - Optional data to render a button.
 * @param sectionData.buttons - Optional array of button data to render multiple buttons.
 * @param sectionData.panels - Optional array of panel data to render nested panels.
 * @param sectionData.rows - Optional array of row data to render nested rows.
 * @param sectionData.items - Optional array of item data to render nested items.
 * @param sectionData.gridItems - Optional array of grid item data to render a grid.
 * @param props - Optional additional properties to pass to the component.
 *
 * @returns A JSX element representing the panel or null if no valid section data is provided.
 */
export function Panel(sectionData: any, props?: any): JSX.Element | null {
    //console.log("Panel...");
    const panelClassName = "panel";
    const styles = { ...sectionData.uiCompType?.styles };
    delete sectionData.uiCompType?.styles;

    return (
        <div id={sectionData.trackId || ('panel_' + CMS_ID_COUNTER++)} className={cssStyles[panelClassName]} style={styles}>
            {Object.keys(sectionData).map((key: string) => {
                const matchedValue = sectionData[key];
                if (key === 'uiCompType') return null;
                const [keyWithExcludedStyles, ...styleParts] = key.split('-');
                const keyWithoutSeqNumber = keyWithExcludedStyles.replace(/\d+$/, '');
                const extracedStyles = extractKeyStyles(styleParts);
                let renderPanelBody = null;
                console.log("sectionData.trackId : " + sectionData.trackId + "  keyWithoutSeqNumber : " + keyWithoutSeqNumber);
                switch (keyWithoutSeqNumber) {
                    case 'space':
                        renderPanelBody = <div key={key} style={{ height: matchedValue }} />;
                        break;
                    case 'h1':
                        renderPanelBody = renderText(matchedValue, 'title', sectionData.uiCompType, props, 'h1', extracedStyles);
                        break;
                    case 'title':
                        renderPanelBody = renderText(matchedValue, 'title', sectionData.uiCompType, props, 'h2', extracedStyles);
                        break;
                    case 'subTitle':
                        renderPanelBody = renderText(matchedValue, 'subTitle', sectionData.uiCompType, props, 'h3', extracedStyles);
                        break;
                    case 'text':
                        renderPanelBody = renderText(matchedValue, 'text', sectionData.uiCompType, props, 'span', extracedStyles);
                        break;
                    case 'description':
                        renderPanelBody = renderText(matchedValue, 'description', sectionData.uiCompType, props, undefined, extracedStyles);
                        break;
                    case 'imageUrl': /* when a simple imageUrl string is giving with some height af # */
                        renderPanelBody = renderFunctions.CustImage({
                            imageUrl: matchedValue,
                            link: sectionData.link
                        }, props);
                        break;
                    case 'imagePanel':
                        renderPanelBody = matchedValue &&
                            renderFunctions.CustImage({
                                ...matchedValue,
                                uiCompType: {
                                    ...matchedValue.uiCompType,
                                    styles: matchedValue.uiCompType?.styles,
                                    parentStyles: matchedValue.uiCompType?.parentStyles,
                                    containerStyles: matchedValue.uiCompType?.containerStyles
                                }
                            }, props);
                        break;
                    case 'videoUrl':
                        renderPanelBody = renderFunctions.CustVideo({
                            videoUrl: matchedValue,
                            link: sectionData.link
                        }, props);
                        break;
                    case 'videoPanel':
                        renderPanelBody = matchedValue &&
                            renderFunctions.CustVideo({
                                ...matchedValue,
                                uiCompType: {
                                    ...matchedValue.uiCompType,
                                    styles: matchedValue.uiCompType?.styles,
                                    parentStyles: matchedValue.uiCompType?.parentStyles,
                                    containerStyles: matchedValue.uiCompType?.containerStyles
                                }
                            }, props);
                        break;
                    case 'button':
                        renderPanelBody = matchedValue && (
                            <div key={key} className={cssStyles[panelClassName + "Button"]} style={matchedValue.uiCompType?.parentStyles}>
                                {getButton(matchedValue, 0)}
                            </div>
                        );
                        break;
                    case 'buttons': // TODO : need to replace with ItemsGrid
                        const buttons = matchedValue as any[];
                        renderPanelBody = buttons.length > 0 && (
                            <div key={key} className={cssStyles[panelClassName + "Buttons"]} style={buttons[0].uiCompType?.parentStyles}>
                                {buttons.map((button: any, index: number) => (
                                    getButton(button, index)
                                ))}
                            </div>
                        );
                        break;
                    case 'panels':
                        renderPanelBody = matchedValue?.map((item: any, index: number) => (
                            renderSection(item.trackId, item, props)
                        ));
                        break;
                    case 'rows':
                        renderPanelBody = matchedValue?.map((item: any, index: number) => (
                            renderSection(item.trackId, item, props)
                        ));
                        break;
                    case 'items':
                        renderPanelBody = matchedValue?.map((item: any, index: number) => (
                            renderSection(item.trackId, item, props)
                        ));
                        break;
                    case 'itemsGrid':
                    case 'gridItems':
                        const uiCompType = {
                            options: {
                                splitRaito: sectionData.uiCompType?.options?.splitRaito,
                                grid: sectionData.uiCompType?.options?.grid || 'spread',
                                spacing: sectionData.uiCompType?.options?.spacing || 2
                            },
                            styles: sectionData.uiCompType?.parentStyles
                        };
                        const gridSectionData = {
                            items: matchedValue,
                            uiCompType: uiCompType
                        };
                        renderPanelBody = ItemsGrid(gridSectionData, props);
                        break;
                    default:
                        const otherObject = (sectionData as any)[key];
                        if (typeof otherObject === 'object') {
                            renderPanelBody = renderSection(otherObject.trackId ?? key, otherObject, props);
                        }
                        break;
                }

                return sectionData.link ? (
                    <a href={sectionData.link} target={sectionData.newTab ? "_blank" : "_self"} id={sectionData.trackId || ('panel_' + CMS_ID_COUNTER++)}
                        onClick={() => { trackItem(sectionData.trackInfo || {}); }}
                    >
                        {renderPanelBody}
                    </a>
                ) : (
                    renderPanelBody
                );

            })}
        </div>
    );
}

/**
 * Renders a ProductsCarousel component.
 *
 * @param {any} sectionData - The data for the section, including items to be displayed in the carousel.
 * @param {any} props - The properties passed to the component.
 * @param {string} props.vendorName - The name of the vendor.
 * @param {object} props.userInfo - Information about the user.
 * @param {Array} props.favorites - List of favorite items.
 * @param {Function} props.setFavorites - Function to update the list of favorite items.
 *
 * @returns {JSX.Element | null} The rendered ProductsCarousel component or null if no items are provided.
 */
export function ProductsCarousel(sectionData: any, props: any): JSX.Element | null {
    console.log("ProductsCarousel...");
    const { vendorName, userInfo, favorites, setFavorites } = props;
    const panelClassName = "carousel";

    const items = generateProductCards(
        prepareCardsForBuilderProductsGrid(sectionData.items as CardData[], getBrandPathEntry(vendorName)),
        handleProductSelection,
        (sectionData.uiCompType?.options?.height || "320px"),
        sectionData.trackId ?? 'productsCarousel_' + CMS_ID_COUNTER++, // trackId
        undefined,
        undefined,
        undefined,
        favorites,
        setFavorites,
        userInfo?.cId
    );

    return CustCarousel({ ...sectionData, items }, props);
}

/**
 * Renders a Slider component using the provided section data and props.
 * 
 * @param sectionData - The data for the section, including UI component types, styles, options, image URLs, and items.
 * @param props - Additional properties passed to the component.
 * @returns A JSX element representing the slider or null if no section data is provided.
 * 
 * The Slider component uses the `ReactMultiCarousel` library to create a responsive carousel. 
 * The carousel configuration includes:
 * 
 * - `responsive`: An object defining breakpoints for different screen sizes (desktop, tablet, mobile) and their respective settings.
 * - `partialVisible`: A boolean indicating whether the carousel should show partially visible slides.
 * - `ssr`: A boolean indicating whether server-side rendering is enabled.
 * - `showDots`: A boolean indicating whether to show navigation dots.
 * - `autoPlay`: A boolean indicating whether the carousel should auto-play.
 * - `dotListClass`: A string defining the CSS class for the dot list.
 * - `swipeable`: A boolean indicating whether the carousel should be swipeable.
 * - `keyBoardControl`: A boolean indicating whether keyboard controls are enabled.
 * - `itemClass`: A string defining the CSS class for each carousel item.
 * - `customRightArrow`: A custom right arrow component for navigation.
 * - `customLeftArrow`: A custom left arrow component for navigation.
 * - `infinite`: A boolean indicating whether the carousel should loop infinitely.
 * - `autoPlaySpeed`: A number indicating the speed of auto-play in milliseconds.
 * - `containerClass`: A string defining the CSS class for the carousel container.
 * 
 * The `sectionData` object includes:
 * 
 * - `uiCompType`: An object containing styles and options for the UI component.
 * - `imageUrls`: An array of image URLs to be displayed in the carousel.
 * - `items`: An array of items to be rendered within the carousel. Items can be any component like Panel, ContentOnImage, etc.
 * - `trackId`: An optional identifier for tracking purposes.
 * 
 * The `CustomRight` and `CustomLeft` components are used to render custom navigation arrows for the carousel.
 * 
 * The `renderSection` function is used to render each slide based on the provided section data.
 */
export function Slider(sectionData: any, props: any): JSX.Element | null {

    console.log("Slider...");
    const styles = sectionData.uiCompType?.containerStyles || { 'height': '500px', 'width': "100%" };
    const imageContainerStyles = { ...sectionData.uiCompType?.imageContainerStyles, height: sectionData.uiCompType?.imageContainerStyles?.height || sectionData.uiCompType?.containerStyles?.height };
    const itemStyles = { ...sectionData.uiCompType?.itemStyles, height: sectionData.uiCompType?.itemStyles?.height ?? sectionData.uiCompType?.containerStyles?.height };
    const options = sectionData.uiCompType?.options || {};

    const CustomRight = ({ onClick }: any) => (
        <button className={`${cssStyles["react-multiple-carousel__arrow"]} ${cssStyles["react-multiple-carousel__arrow--right"]}`} 
            onClick={onClick} style={sectionData.uiCompType?.rightArrowStyles}>
            <NavigateNextIcon />
        </button>
    );
    const CustomLeft = ({ onClick }: any) => (
        <button className={`${cssStyles["react-multiple-carousel__arrow"]} ${cssStyles["react-multiple-carousel__arrow--left"]}`} 
            onClick={onClick} style={sectionData.uiCompType?.leftArrowStyles}>
            <NavigateBeforeIcon />
        </button>
    );
    if (sectionData.items) {
        sectionData.items = sectionData.items.map((item: any) => {
            if (item.uiCompType?.styles) {
                item.uiCompType.styles = { ...item.uiCompType.styles, ...itemStyles };
            } else {
                item.uiCompType = { ...item.uiCompType, styles: itemStyles };
            }
            return item;
        });
    }
    const panelClassName = "slider";

    const responsive={                    
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1,
            partialVisibilityGutter: 50,
            ...options.carouselProps?.responsiveCust?.desktop
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1,
            partialVisibilityGutter: 30,
            ...options.carouselProps?.responsiveCust?.tablet
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,
            partialVisibilityGutter: 20,
            ...options.carouselProps?.responsiveCust?.mobile
        }
    };
    function getCarouselChildren(sectionData: any, props: any): JSX.Element[] {
        if (sectionData.imageUrls) {
            return sectionData.imageUrls.map((imageUrl: string, index: number) => (
                <div key={index} className={cssStyles[panelClassName + "ImageContainer"]} style={{ position: 'relative', ...imageContainerStyles }}>
                    <Image
                        alt={sectionData.title || ''}
                        src={imageUrl}
                        layout="fill"
                        objectFit="cover"
                    />
                </div>
            ));
        } else if (sectionData.items) {
            return sectionData.items.map((item: any, index: number) => (
                <div key={index} className={cssStyles[panelClassName + "ItemContainer"]} style={{ position: 'relative', ...imageContainerStyles }}>
                    {renderSection(item.trackId, item, props)}
                </div>
            ));
        } else {
            return [];
        }
    }
    return (
        <div style={styles}>
            <ReactMultiCarousel
            afterChange={(previousSlide, { currentSlide, totalItems }) => {
            console.log("afterChange : currentSlide", currentSlide, "previousSlide", previousSlide,  "totalItems", totalItems);
            }}
            beforeChange={(nextSlide, { currentSlide, totalItems }) => {
            console.log("beforeChange: currentSlide", currentSlide,  "nextSlide", nextSlide, "totalItems", totalItems);
            }}
            responsive={responsive}
            partialVisible={false}
            ssr={true}
            showDots={sectionData.imageUrls?.length > 1} 
            arrows={sectionData.imageUrls?.length > 1 || sectionData.items?.length > 1}
            autoPlay={false}
            dotListClass={cssStyles["designer-carousel-dot-list"]}
            swipeable={true}
            keyBoardControl={true}
            itemClass="carousel-item-padding-40-px"
            customRightArrow={<CustomRight />}
            customLeftArrow={<CustomLeft />}
            infinite={true}
            {...options.carouselProps}
            > 
                {getCarouselChildren(sectionData, props)}
            </ReactMultiCarousel>
        </div>
    );
}

export function SubTitle(sectionData: any): JSX.Element | null {
    return renderText(sectionData.subTitle , "subTitle", sectionData.uiCompType, {} , 'h3') || null;
}

export function Title(sectionData: any): JSX.Element | null {
    return renderText(sectionData.title, "title", sectionData.uiCompType, {} , 'h2') || null;
}

export function transformDescription(items: string[] | string, panelClassName?: string, trackId?: any, style?: React.CSSProperties, elemType?: string): React.ReactNode {
    if (Array.isArray(items)) {
        return items.map((descItem: string, index: number) => (
            transformTextToHtml(descItem, panelClassName, trackId + index, style, elemType)
        ));
    } else if (typeof items === 'string') {
        return transformTextToHtml(items, panelClassName, trackId, style, elemType);
    } else {
        console.error('Invalid items type:', typeof items);
        return null;
    }
}
export const transformTextToHtml = (text: string, panelClassName?: string, id?: string, style?: React.CSSProperties, elemType: string = 'p') => {

    if (!text) {
        return null;
    }

    if (!panelClassName) {
        panelClassName = "";
    }

    let elemTypeKey: keyof JSX.IntrinsicElements = elemType as keyof JSX.IntrinsicElements;
    
    function transformMarkdown(text: string, classNameStr: string, elemTypeKey: keyof JSX.IntrinsicElements, id?: string, style?: React.CSSProperties, aStyle?: React.CSSProperties) {

        return (
            <ReactMarkdown components={{
            p: ({ node, ...props }) => {
                return React.createElement(
                elemTypeKey,
                { id, className: cssStyles[classNameStr] || classNameStr, style: { ...style, margin: '2px'} },
                props.children
                );
            },
            a: ({ node, ...props }) => <a key={id || 'link'} 
                className={cssStyles[classNameStr] || classNameStr} 
                style={{ color: 'black', textDecoration: 'none', ...aStyle }} 
                {...props} />
            }}>{text}</ReactMarkdown>
        );
    }
    try {
        let styleMatch = text.match(/\(STYLE=({.*?})\)(.*?)\(\/STYLE\)/i);
        if (styleMatch) {
            let styleObject: { aStyles?: React.CSSProperties } = JSON.parse(styleMatch[1]?.replace(/'/g, '"') || '{}');
            const aStyleObject: React.CSSProperties = styleObject?.aStyles || {};
            return transformMarkdown(styleMatch[2], panelClassName, elemTypeKey, id, { ...style, ...styleObject }, aStyleObject);
        }

        styleMatch = text.match(/<STYLE=({.*?})>(.*?)<\/STYLE>/i);
        if (styleMatch) {
            let styleObject: { aStyles?: React.CSSProperties } =  JSON.parse(styleMatch[1]?.replace(/'/g, '"') || '{}');
            const aStyleObject = (styleObject as { aStyles?: React.CSSProperties })?.aStyles || {};
            return transformMarkdown(styleMatch[2], panelClassName, elemTypeKey, id, { ...style, ...styleObject }, aStyleObject);
        }

        if (text.match(/^<SMALL>/i)) {
            return transformMarkdown(text.replace(/<SMALL>/i, '').replace(/<\/SMALL>/i, ''), panelClassName + "SmallText", elemTypeKey, id, style)
        } else if (text.match(/^<LARGE>/i)) {
            return transformMarkdown(text.replace(/<LARGE>/i, '').replace(/<\/LARGE>/i, ''), panelClassName + "LargeText", elemTypeKey, id, style)
        } else if (text.match(/^<CENTER>/i)) {
            return transformMarkdown(text.replace(/<CENTER>/i, '').replace(/<\/CENTER>/i, ''), panelClassName + "CenterText", elemTypeKey, id, style);
        } else if (text.match(/^<BOLD>/i)) {
            return transformMarkdown(text.replace(/<BOLD>/i, '').replace(/<\/BOLD>/i, ''), panelClassName + "BoldText", elemTypeKey, id, style);
        } else if (text.match(/^ELINE$/i) || text.trim() === "") {
            return <div key={id}>&nbsp;</div>;
        } else {
            const spaceMatch = text.match(/\(SPACE=({.*?})\)/i);
            if (spaceMatch) {
                const styleObject = JSON.parse(spaceMatch[1].replace(/'/g, '"'));
                return <div key={id} style={styleObject} />;
            }

            const lineMatch = text.match(/\(LINE=({.*?})\)/i);
            if (lineMatch) {
                const styleObject = JSON.parse(lineMatch[1].replace(/'/g, '"'));
                return <hr key={id} style={styleObject} />;
            }
            return transformMarkdown(text, panelClassName, elemTypeKey, id, style);
        }
    } catch (error) {
        throw new Error(`Failed to parse style object. Original error: ${error}. Input text: ${text}`);
    }
}
/**
 * Renders a custom video component with optional link and tracking.
 *
 * @param {any} sectionData - The data for the section containing video information and styles.
 * @param {number} [index=1] - The index of the video, default is 1.
 * @returns {JSX.Element | null} The rendered video component or null.
 *
 * @example
 * // Example sectionData structure:
 * {
 *   videoUrl: "https://example.com/video.mp4",
 *   title: "Example Video",
 *   link: "https://example.com",
 *   newTab: true,
 *   trackId: "video1",
 *   trackInfo: { ... },
 *   uiCompType: {
 *     styles: { width: "100px", height: "100px" },
 *     containerStyles: { ... },
 *     parentStyles: { ... },
 *     options: { objectFit: "cover" }
 *   }
 * }
 *
 * @example
 * // Example usage:
 * <CustVideo sectionData={sectionData} index={2} />
 */
/**
 * Renders a custom video component with optional link wrapping.
 *
 * @param sectionData - The data for the section containing video information and styles.
 * @param sectionData.videoUrl - The URL of the video to be displayed.
 * @param sectionData.uiCompType - The UI component type containing styles and options.
 * @param sectionData.uiCompType.styles - The styles to be applied to the video element.
 * @param sectionData.uiCompType.containerStyles - The styles to be applied to the video container.
 * @param sectionData.uiCompType.parentStyles - The styles to be applied to the parent container.
 * @param sectionData.uiCompType.options - The options for the video element.
 * @param sectionData.uiCompType.options.autoPlay - Whether the video should autoplay.
 * @param sectionData.uiCompType.options.loop - Whether the video should loop.
 * @param sectionData.uiCompType.options.muted - Whether the video should be muted.
 * @param sectionData.uiCompType.options.poster - The poster image for the video.
 * @param sectionData.uiCompType.options.preload - The preload setting for the video.
 * @param sectionData.uiCompType.options.playsInline - Whether the video should play inline.
 * @param sectionData.link - The URL to wrap the video in a link.
 * @param sectionData.newTab - Whether the link should open in a new tab.
 * @param sectionData.trackInfo - The tracking information for the video.
 * @param sectionData.trackId - The tracking ID for the video.
 * @param index - The index of the video in the list (default is 1).
 * @returns A JSX element containing the video, optionally wrapped in a link, or null if no video URL is provided.
 */
export function CustVideo(sectionData: any, index: number = 1): JSX.Element | null {
    console.log("CustVideo...");
    const { stylesFromUrl, urlStr } = extractStylesFromUrl(sectionData.videoUrl as string);
    const videoStyles = { ...sectionData.uiCompType?.styles, ...stylesFromUrl };
    const videoContainerStyles = { ...sectionData.uiCompType?.containerStyles, ...sectionData.uiCompType?.parentStyles };

    // moving videoStyles to ContainerStyles when the containerStyles does not have height or width
    if (!videoContainerStyles.height && !videoContainerStyles.width) {
        if (videoStyles.width) {
            videoContainerStyles.width = `${parseDimension(videoStyles.width as string | number)}px`;
        }

        if (videoStyles.height) {
            videoContainerStyles.height = `${parseDimension(videoStyles.height as string | number)}px`;
            if (!videoContainerStyles.width) {
                videoContainerStyles.width = '100%';
            }
        }
    }

    const video = (
        <video
            src={urlStr as string}
            style={videoStyles}
            className={cssStyles.video}
            autoPlay={sectionData.uiCompType?.options?.autoPlay || false}
            loop={sectionData.uiCompType?.options?.loop ?? false}
            muted={sectionData.uiCompType?.options?.muted ?? false}
            poster={sectionData.uiCompType?.options?.poster || ''}
            preload={sectionData.uiCompType?.options?.preload || 'auto'}
            playsInline={sectionData.uiCompType?.options?.playsInline || false}
            controls={sectionData.uiCompType?.options?.controls ?? true}
        />
    );

    return (
        <div>
            {sectionData.link ? (
                <a href={sectionData.link} target={sectionData.newTab ? "_blank" : "_self"}
                    onClick={() => { trackItem(sectionData.trackInfo || {}); }}
                >
                    <div key={sectionData.trackId as string + index} className={cssStyles.videoContainer} style={{ position: 'relative', ...videoContainerStyles }}>
                        {video}
                    </div>
                </a>
            ) : (
                <div key={(sectionData.trackId || CMS_ID_COUNTER++) + index} className={cssStyles.videoContainer} style={{ position: 'relative', ...videoContainerStyles }}>
                    {video}
                </div>
            )}
        </div>
    );
}


const generateProductCardsData2 = (data: any, vendorName: string) => {

    return data?.products?.map((product: any) => {
        let pdpUrl = getBrandPathEntry(vendorName) + `/product/${product.productId}`;
        let roomTypeParam = product.roomType ? '&roomType=' + product.roomType : '';
        let productTypeParam = product.productType ? '&productType=' + product.productType : '';
        let viewInYourRoomLink = `/builder?productId=${product.productId}&showcase=true&auth=false${roomTypeParam}${productTypeParam}`;
        return {
            name: product.productName,
            id: product.productId,
            image: product.productImage,
            model3dUrl: product.model3dUrl,
            pdpUrl: pdpUrl,
            viewMoreUrl: "",
            price: product.price,
            comparePrice: product.comparePrice,
            viewInYourRoomLink: viewInYourRoomLink,
            otherSizes: product.otherSizes,
            otherColors: product.otherColors,
            // otherFabrics: product.otherFabrics,
            // otherConfigs: product.otherConfigs,
            // configurationIcon: product.configurationIcon,
            priceRange: product.priceRange,
            expiresOn: product.expiresOn ? `Ends : ${new Date(product.expiresOn).toLocaleDateString()}` : 'While supplies last.',
            isBestSeller: product.isBestSeller
        };
    });
}

export const  CMS_HOST_URL = 'https://d2ffb7z1ailwvo.cloudfront.net/'

export const getCmsData = async (vendorName: string, cmsRelUrl: string, env: string) => {
    let cmsData: {
        title: string;
        description: string;
        order?: string[];
        sections?: { [s: string]: any; };
        list?: Array<any>;
        items?: Array<any>;
        uiCompType?: {
            method: string;
        };
        trackInfo?: {
            trackId?: string;
            trackType?: string;
            pageName?: string;
            pageType?: string;
            eventName?: string;
            eventType?: string;
            eventCategory?: string;
            eventAction?: string;
            eventLabel?: string;
            eventValue?: string;
        }
    } = {
        sections: {},
        list: [],
        title: "",
        description: "",
        order: [],
        items: []
    };
    try {
        let cmsDataPromise = await fetch(`${CMS_HOST_URL}${cmsRelUrl}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        cmsData = await cmsDataPromise.json();
        //cmsData = jsonObj;
        type PreprocessorsType = {
            [key: string]: (productIds: string[]) => Promise<any>;
        };

        const preprocessors: PreprocessorsType = {
            getProductDetails: async (productIds: string[]) => {
                let productsDataPromise = await getProductsByIds({ productIds: productIds, env: env, brand: vendorName });
                console.log('getProductDetails: productsDataPromise', productsDataPromise);
                return productsDataPromise;
            }
        };

        const processSections = async () => {
            try {
                const sectionPromises = Object.values(cmsData.sections || {}).map(async (section: any) => {
                    try {
                        if (section.preprocess && section.preprocess.method && section.preprocess.input) {
                            const data = await preprocessors[section.preprocess.method](section.preprocess.input);
                            console.log('Processed data:', data);
                            const processedData = generateProductCardsData2(data, vendorName);
                            section.items = processedData;
                        }
                    } catch (sectionError) {
                        console.error(`Error processing section ${section.name}:`, sectionError);
                    }
                    if (section.include) {
                        try {
                            const includeUrl = new URL(section.include, `${CMS_HOST_URL}${env == 'qa' ? 'stage' : 'prod'}/`);
                            const includeDataPromise = await fetch(includeUrl.toString(), {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });
                            const includeData = await includeDataPromise.json();
                            Object.assign(section, includeData);
                        } catch (includeError) {
                            console.error(`Error including data for section ${section.name}:`, includeError);
                        }
                    }
                });

                await Promise.all(sectionPromises);
                console.log('All sections processed');
                return cmsData;
            } catch (error) {
                console.error('Error processing sections:', error);
                return null;
            }
        };

        await processSections().then((result) => {
            if (result) {
                console.log('Processing completed successfully');
            } else {
                console.log('Processing failed');
            }
        }).catch((error) => {
            console.error('Unexpected error:', error);
        });
        return cmsData;

    } catch (e) {
        console.error("Error in fetching cms data", e);
    }
}

const getBrandPathEntry = (vendorName: string) => {
    return vendorName && vendorName !== 'nestingale' ? `/${vendorName}` : "";
}

export const renderFunctions: RenderFunctionType = {
    Accordion,
    CategoriesCarousel,
    CirclerImageButton,
    ContentOnImage,
    CustButton,
    CustImage,
    CustText,
    CustVideo,
    ImageButton,
    ItemsGrid,
    MarkdownTextPanel,
    Panel,
    ProductsCarousel,
    Slider
};

export const renderSection = (sectionKey: string, sectionData: any, props: any, isSection?: boolean) => {
    console.log("renderSection...sectionKey: ", sectionKey);
    sectionData = sectionData || props.message.cmsData.sections[sectionKey];
    if (!sectionData) {
        console.error(`No section found for key: ${sectionKey}`);
        return null;
    }
    let renderFunction: string = sectionData.uiCompType?.method?.replace(/^render/, '') || "Panel";
    const sectionStyles = sectionData.uiCompType?.sectionStyles || {};

    return (
        isSection ? (
            <section key={sectionKey} style={sectionStyles} className={cssStyles.section}>
                {renderFunction && renderFunction in renderFunctions
                    ? renderFunctions[renderFunction](sectionData, props)
                    : Panel(sectionData, props)}
            </section>
        ) : (
            <>
                {renderFunction && renderFunction in renderFunctions
                    ? renderFunctions[renderFunction](sectionData, props)
                    : Panel(sectionData, props)}
            </>
        )
    );
};

/**
 * CmsRenderer component is responsible for rendering CMS sections based on the provided props.
 * 
 * @param {any} props - The properties passed to the component.
 * @param {string} props.message - A JSON string containing vendorName and cmsData.
 * @param {object} props.sectionCmsData - Data for a specific CMS section.
 * @param {string} props.sectionCmsKey - Key for a specific CMS section.
 * 
 * @returns {JSX.Element} The rendered CMS sections.
 * 
 * The component first parses the `props.message` to extract `vendorName` and `cmsData`.
 * It then checks if `sectionCmsData` and `sectionCmsKey` are provided.
 * If they are, it renders the specific section using `renderSection` function.
 * If not, it iterates over the `order` array in `cmsData` to render each section in the specified order.
 * 
 * The `order` array in the `cmsData` is crucial as it determines the sequence in which the sections are rendered.
 * Each key in the `order` array corresponds to a section in the `sections` object of `cmsData`.
 * The `renderSection` function is called for each section key, passing the section data and other props.
 */
const CmsRenderer = (props: any) => {
    let vendorName: String, cmsData: any;
    try {
        ({ vendorName, cmsData } = JSON.parse(props.message));
    } catch (error) {
        console.error("Error parsing CMS data:", error);
        return <div>Error loading CMS data. Please try again later. Error : {String(error)}</div>;
    }

    const { sectionCmsData, sectionCmsKey } = props;

    return (
        <>
            {(sectionCmsData && sectionCmsKey)
                ? <React.Fragment key={sectionCmsKey}>
                    {renderSection(sectionCmsKey, sectionCmsData, props, true)}
                </React.Fragment>
                : cmsData.order?.map((secKey: string, index: number) => (
                    <React.Fragment key={secKey}>
                        {secKey && cmsData.sections[secKey] && renderSection(secKey, cmsData.sections[secKey], props, (index == 0 ? false : true))}
                    </React.Fragment>
                ))}
        </>
    )
}

export default CmsRenderer;
