import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BuilderSelect from "@/store/builderStoreSelector";
import { BuilderProduct, Project, RoomModel, TwoDRoomLayout, actions as builderActions } from "@/store/builderSlice";
import { BUILDER_FAILED, BUILDER_IN_PROGRESS, BUILDER_SUCCESS, DimensionalViewType } from "@/utils/builder/builderConstants";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Divider, Grid, List, ListItem } from "@mui/material";
import BuyNowComponent from "./BuyNowComponent";
import { useShopify } from "@/store/shopifyReducer";
import { BorderColor } from "@mui/icons-material";

const ProjectSummary = () => {
    const {checkoutState}=useShopify()
    const existingCart = checkoutState.lineItems?.map((lineItem:any) => lineItem.variant.sku) || [];
    const project: Project = BuilderSelect.project();
    const projectProducts: BuilderProduct[] = project.products;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getTotalPrice = () => {
        return projectProducts.reduce((totalPrice, curProduct) => totalPrice + curProduct.price, 0).toFixed(2);
    }

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const tooltipCss = {
        position: "relative",
        mt: "25px",
        "&::before": {
            backgroundColor: "white",
            content: '""',
            display: "block",
            position: "absolute",
            width: 30,
            height: 20,
            top: 0,
            transform: "rotate(45deg)",
            left: "calc(65%)"
        }
    }

    return (
        <Box component={'div'}
            sx={{
                paddingRight: '10px'
            }}>
                <Grid container sx={{columnGap:'10px'}}>
            <Typography fontSize={14} color={"#A0A4A8"}> Total: $ <span style={{fontWeight:'bold'}}>{getTotalPrice()}</span></Typography>
            <Typography
                fontSize={14}
                textAlign={'center'}
                fontWeight={'bold'}
                onClick={handleClick}
                color={'#0D908E'}
                style={{ cursor: 'pointer' ,textDecoration:'underLine'}}
            >
                Details
            </Typography>
            </Grid>
            <Popover
                id={id}
                open={open&&(projectProducts.length>0)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        borderRadius: 0,
                        transform: "translateX(-100px)" ,
                        marginTop:'10px'
                    }
                }}
            >
                <Box component={'div'} sx={tooltipCss} />
                <Box component={'div'}
                    sx={{
                        width: '400px',
                        bgcolor: 'background.paper',
                        borderRadius: '5px',
                        margin: '5px',
                        padding:'10px'
                    }}>
                    <List >
                        {projectProducts.map((product: BuilderProduct, index: number) => (
                            <ListItem key={index + product.productId} sx={{ padding: '0px 0' }}>
                                <Grid container justifyContent="space-between" alignItems="center" >
                                    <Grid container width={'80%'} sx={{display:'flex',alignItems:'center',columnGap:'30px'}}>
                                    <Grid item width={'30%'} sx={{ paddingLeft: '5px'}}>
                                        <img
                                            src={product.productImage}
                                            style={{ maxWidth: '100px',borderRadius:'10px' }}
                                            draggable={false}
                                        />
                                    </Grid>
                                    <Grid item width={'50%'} sx={{ padding:0 }}>
                                        <Typography gutterBottom variant="h5" component="div"
                                            sx={{
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                WebkitLineClamp: 2, // Limit to 2 lines
                                                lineClamp: 2,       // For non-webkit browsers
                                                fontSize: '14px',
                                              }}
                                        >
                                            {product.productName}
                                        </Typography>
                                    </Grid >
                                    </Grid>
                                    <Grid item width={'20%'} sx={{ paddingRight: '5px' }} container flexDirection='column' justifyContent='center' alignItems='center'>
                                        <Typography
                                            fontSize={16}
                                            textAlign={'right'}
                                            fontWeight={'bold'}
                                            onClick={handleClick}
                                            style={{ cursor: 'grab' }}
                                        >
                                            ${product.price}
                                        </Typography>
                                        {existingCart.includes(product.productId)&&<Typography fontWeight={'bold'} fontSize={12} color={'#0D908E'}>In Cart</Typography>}
                                    </Grid >
                                </Grid>
                            </ListItem>
                        ))}
                    </List>
                    <Divider flexItem sx={{ borderRightWidth: 2 }} />
                    <Grid container justifyContent="space-between" alignItems="center" sx={{ paddingTop: '10px',columnGap:'20px' }}>
                        <Grid item  sx={{ paddingLeft: '5px' }}>
                            <Typography
                                fontSize={14}
                                fontWeight={'bold'}
                            >
                                Total
                            </Typography>
                        </Grid>
                        <Grid item sx={{ paddingRight: '5px' }}>
                            <Typography
                                fontSize={14}
                                fontWeight={'bold'}
                                textAlign={'right'}
                            >
                                ${getTotalPrice()}
                            </Typography>
                        </Grid >
                    </Grid>
                    {/* <Grid container justifyContent="center" alignItems="center" sx={{ padding: '5px 5px 10px 5px' }}>
                        <BuyNowComponent />
                    </Grid> */}
                </Box>
            </Popover>
        </Box>
    );
}

export default ProjectSummary;