import { createSlice } from '@reduxjs/toolkit';
import { fetchFavoritesThunk, toggleFavoriteThunk } from './thunks/favoriteThunk/favoriteThunk';
interface FavoritesState {
    products: any;
    designs: any;
    loading: boolean;
    error: string | null;
}
// Initial State
const initialState: FavoritesState = {
    products: {},
    designs: {},
    loading: false,
    error: null,
};

const favoriteSlice = createSlice({
    name: 'favorites',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch Favorites
            .addCase(fetchFavoritesThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchFavoritesThunk.fulfilled, (state, { payload }) => {
                state.loading = false;

                // Separate products and designs
                state.products = {};
                state.designs = {};

                payload.forEach((item: any & { type: 'product' | 'design' }) => {
                    if (item.type === 'product') {
                        state.products[item.itemId] = { ...item, isFavorite: true } as any;
                    } else if (item.type === 'design') {
                        state.designs[item.itemId] = { ...item, isFavorite: true, } as any;
                    }
                });
            })
            .addCase(fetchFavoritesThunk.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload as string;
            })

            // Toggle Favorite (Optimistic)
            .addCase(toggleFavoriteThunk.pending, (state, { meta }) => {
                const { postData } = meta.arg;
                const { itemId, type, isFavorite } = postData

                if (type === 'product') {
                    if (isFavorite) {
                        delete state.products[itemId];
                    } else {
                        state.products[itemId] = { ...postData, isFavorite: true } as any;
                    }
                } else if (type === 'design') {
                    if (isFavorite) {
                        delete state.designs[itemId];
                    } else {
                        state.designs[itemId] = { ...postData, isFavorite: true } as any;
                    }
                }
            })
            .addCase(toggleFavoriteThunk.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(toggleFavoriteThunk.rejected, (state, { meta }) => {
                const { postData } = meta.arg;
                const { itemId, isFavorite, type } = postData
                // Revert state on error
                if (type === 'product') {
                    if (!isFavorite) {
                        delete state.products[itemId];
                    } else {
                        state.products[itemId] = { ...postData, isFavorite: true } as any;
                    }
                } else if (type === 'design') {
                    if (!isFavorite) {
                        delete state.designs[itemId];
                    } else {
                        state.designs[itemId] = { ...postData, isFavorite: true } as any;
                    }
                }
                state.error = 'Failed to update favorite';
            });
    },
});

export default favoriteSlice.reducer;
