import { BuilderDesign, DesignStyle } from "@/store/builderSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getValidProducts } from "./builderProductsLoader";

const axiosObj = axios.create({
    baseURL: "https://d221a61rb87vel.cloudfront.net/"
});
const loadedDesignsMap: any = {
};

export const updateLoadedDesignsMap = (roomName: string, styleName: string) => {
    if (!roomName || !styleName) return;
    let curRoomNames: string[] = loadedDesignsMap[roomName.toLowerCase()];
    if (!curRoomNames) {
        curRoomNames = [];
    }
    curRoomNames.push(styleName.toLowerCase());
    loadedDesignsMap[roomName.toLowerCase()] = curRoomNames;
};

export const isDesignLoaded = (roomName: string, styleName: string) => {
    return !!(loadedDesignsMap[roomName.toLowerCase()] && (loadedDesignsMap[roomName.toLowerCase()]).includes(styleName.toLowerCase()));
};

const getValidDesigns = (designs: BuilderDesign[], styleId: string, style: string) => {
    const validDesigns = designs.filter(design => {
        design.style = style;
        design.styleId = styleId;
        const validProducts = getValidProducts(design.products);
        if (validProducts.length > 0 && design.products.length !== validProducts.length) {
            design.products = validProducts;
        }
        return validProducts.length > 0;
    });
    return validDesigns;
}
export const getBuilderDesignData = async (config: { roomType?: string, styleName?: string, designId?: string }) => {
    let url = '';
    if (config.roomType) {
        url += `v2/api/designs?room=${config.roomType.toLowerCase()}`
    }
    if(config.styleName){
        url += `&style=${config.styleName.toLowerCase()}`
    }
    if (config.designId) {
        url += `v2/api/designs?designId=${config.designId}`
    }
    if (!url) {
        url = `/v2/api/designs?has3DSupport=true`
    }

    return await axiosObj.get(url)
        .then((response) => {
            // console.log("---- fetch designs : URL : " + url + " responnse : " + JSON.stringify(response.data));
            const designData = response.data;
            const result: any = {};
            result[designData.roomName] = {
                [designData.vendorName]: {
                    [designData.style]: getValidDesigns(designData.designs, designData.styleId, designData.style)
                }
            };
            return result;
        }, (err) => {
            const errorObj = Object.assign({ description: "NEST_SPA::getBuilderDesignData:Exception", errorCode: "NEST_SPA:BUILDER_PAGE" }, err)
            throw errorObj;
        })
        .finally(() => {
            if (config.roomType && config.styleName) {
                updateLoadedDesignsMap(config.roomType.toLowerCase(), config.styleName.toLowerCase());
            }
        });
}

export const loadDesign = createAsyncThunk(
    "builder/loadDesign",
    async (config: { roomName?: string, styleName?: string,designId?:string }, { rejectWithValue, getState }) => {
        try {
            const designData = await getBuilderDesignData({ roomType: config.roomName, styleName: config.styleName,designId:config.designId });
            return designData;
        } catch (err) {
            console.log({ description: "NEST_SPA:Slices:loadDesign:Exception", errorCode: "NEST_SPA:BUILDER_PAGE:LOAD_DESIGN", err_desc: (err as Error).message, err_stack: (err as Error).stack });
            return rejectWithValue(err);
        }
    }
);

export const getBuilderDesignStylesData = async (config: { roomName: string }) => {
    let url = `v2/api/rooms?room=${config.roomName.toLowerCase()}&has3DSupport=true`
    return await axiosObj.get(url)
        .then((response) => {
            // console.log("---- fetch design styles: URL : " + url + " responnse : " + JSON.stringify(response.data));
            const designStylesData = response.data;
            let designStyles: DesignStyle[] = [];
            const roomStyles: any[] = designStylesData.rooms;
            if (roomStyles) {
                roomStyles.forEach(roomStyle => {
                    if (roomStyle.name === config.roomName.toLowerCase()) {
                        const supportedStyles: any[] = roomStyle.supportedStyles;
                        if (supportedStyles) {
                            designStyles = designStyles.concat(supportedStyles)
                        }
                    }
                })
            }
            return designStyles;
        }, (err) => {
            const errorObj = Object.assign({ description: "NEST_SPA::getBuilderDesignData:Exception", errorCode: "NEST_SPA:BUILDER_PAGE" }, err)
            throw errorObj;
        });
}

export const loadDesignStyles = createAsyncThunk(
    "builder/loadDesignStyles",
    async (config: { roomName: string }, { rejectWithValue, getState }) => {
        try {
            const designStylesData = await getBuilderDesignStylesData({ roomName: config.roomName });
            return designStylesData;
        } catch (err) {
            console.log({ description: "NEST_SPA:Slices:loadDesignStyles:Exception", errorCode: "NEST_SPA:BUILDER_PAGE:LOAD_DESIGN_STYLES", err_desc: (err as Error).message, err_stack: (err as Error).stack });
            return rejectWithValue(err);
        }
    }
);